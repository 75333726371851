import cookie from 'react-cookies'
import { isLocal } from '../constants/config'

export const NUGGET_TOKEN = 'NUGGET_TOKEN'
export const NUGGET_USER = 'NUGGET_USER'
export const NUGGET_SANDTOKEN = 'NUGGET_SANDTOKEN'
export const NUGGET_SANDUSER = 'NUGGET_SANDUSER'
export const NUGGET_CANDIDATE = 'NUGGET_CANDIDATE'
export const NUGGET_LOG_TIME = 'NUGGET_LOG_TIME'
export const NUGGET_SLOG_TIME = 'NUGGET_SLOG_TIME'
export const LOG_OUT_TIME = 172800000

export const saveToken = token => {
	localStorage.setItem(NUGGET_TOKEN, token)
}

export const getToken = () => {
	const token = localStorage.getItem(NUGGET_TOKEN)
	return token
}

export const removeToken = () => {
	localStorage.removeItem(NUGGET_TOKEN)
	cookie.remove('sandbox_token', {
		domain: !isLocal ? '.nuggetai.com' : window.location.hostname,
		secure: !isLocal,
		path: '/'
	})
}

export const saveSandToken = token => {
	localStorage.setItem(NUGGET_SANDTOKEN, token)
}

export const getSandToken = () => {
	const token = localStorage.getItem(NUGGET_SANDTOKEN)
	return token
}

export const removeSandToken = () => {
	localStorage.removeItem(NUGGET_SANDTOKEN)
}

export const saveLogTime = () => {
	localStorage.setItem(NUGGET_LOG_TIME, new Date().getTime())
}

export const getLogTime = () => {
	const time = localStorage.getItem(NUGGET_LOG_TIME)
	return time
}

export const removeLogTime = () => {
	localStorage.removeItem(NUGGET_LOG_TIME)
}

export const saveSLogTime = () => {
	localStorage.setItem(NUGGET_SLOG_TIME, new Date().getTime())
}

export const getSLogTime = () => {
	const time = localStorage.getItem(NUGGET_SLOG_TIME)
	return time
}

export const removeSLogTime = () => {
	localStorage.removeItem(NUGGET_SLOG_TIME)
}

export const isExpired = () => {
	const logTime = getLogTime()
	if (logTime && new Date().getTime() - logTime > LOG_OUT_TIME) {
		removeLogTime()
		return true
	}
	return false
}

export const isSandExpired = () => {
	const logTime = getSLogTime()
	if (logTime && new Date().getTime() - logTime > LOG_OUT_TIME) {
		removeSLogTime()
		return true
	}
	return false
}

export const saveUser = user => {
	localStorage.setItem(NUGGET_USER, JSON.stringify(user))
}

export const getUser = () => {
	const user = localStorage.getItem(NUGGET_USER)
	return user ? JSON.parse(user) : null
}

export const existUser = () => {
	const user = localStorage.getItem(NUGGET_USER)
	return !!user
}

export const removeUser = () => {
	removeToken()
	localStorage.removeItem(NUGGET_USER)
}

export const saveSandUser = user => {
	localStorage.setItem(NUGGET_SANDUSER, JSON.stringify(user))
}

export const getSandUser = () => {
	const user = localStorage.getItem(NUGGET_SANDUSER)
	return user ? JSON.parse(user) : null
}

export const removeSandUser = () => {
	removeToken()
	localStorage.removeItem(NUGGET_SANDUSER)
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import { getFixedNumber } from '../../utils/stringHelper'

HighchartsMore(Highcharts)

export class BubbleChart extends Component {
	constructor(props, context) {
		super(props, context)
		this.chartComponent = React.createRef()
		this.state = {
			currentData: {}
		}
	}

	componentDidMount() {
		const container = this.chartComponent.current.container.current
		container.style.width = '100%'
		container.style.height = 'auto'
		this.chartComponent.current.chart.reflow()
	}

	componentWillReceiveProps = nextProps => {
		if (nextProps.data !== this.props.data) {
			this.setState({
				currentData: this.props.data
			})
		}
	}

	clickBubble(data) {
		this.props.onSelectBubble(data)
	}

	render() {
		const { title, data } = this.props
		const series = []

		data.forEach(item => {
			series.push({
				name: item.label,
				cursor: 'pointer',
				color: item.color,
				data: [
					{
						name: item.label,
						value: getFixedNumber(item.value),
						color: item.color,
						description: item.description,
						positions: item.positions
					}
				]
			})
		})

		const options = {
			chart: {
				type: 'packedbubble',
				backgroundColor: 'transparent',
				height: 500
			},
			credits: {
				enabled: false
			},
			title: {
				text: title
			},
			tooltip: {
				useHTML: true,
				backgroundColor: 'white',
				borderWidth: 0,
				pointFormat: '<b>{point.y}%</b> ',
				hearderFormat: "<span style='font-size: 19px'>{point.key}</span><br/>",
				style: {
					color: '#505d6f',
					cursor: 'default',
					fontSize: '16px',
					pointerEvents: 'none',
					whiteSpace: 'nowrap'
				}
			},
			plotOptions: {
				packedbubble: {
					minSize: '70%',
					maxSize: '130%',
					zMin: 0,
					zMax: 1000,
					layoutAlgorithm: {
						splitSeries: false,
						parentNodeLimit: true,
						gravitationalConstant: 0.02,
						initialPositions: 'random'
					},
					events: {
						click: event => {
							this.clickBubble({
								...event.point
							})
						}
					},
					states: { inactive: { opacity: 0.9 } },
					dataLabels: {
						enabled: true,
						// format: '{point.name}',
						formatter: function () {
							if (this.point.name !== null && this.point.name !== '') {
								return this.point.name.match(/\b(\w)/g).join('')
							}
						},
						filter: {
							property: 'y',
							operator: '>',
							value: 0
						},
						style: {
							color: 'black',
							fontSize: '15px',
							textOutline: 'none',
							fontWeight: 'normal'
						}
					}
				}
			},
			series: series,
			legend: {
				itemMarginTop: 5,
				itemMarginBottom: 5
			}
		}
		return (
			<div className={this.props.className}>
				<HighchartsReact
					ref={this.chartComponent}
					allowChartUpdate={true}
					highcharts={Highcharts}
					options={options}
				/>
			</div>
		)
	}
}

BubbleChart.propTypes = {
	title: PropTypes.string,
	data: PropTypes.array,
	onSelectBubble: PropTypes.func.isRequired
}
BubbleChart.defaultProps = {
	labels: [],
	title: ''
}

import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button, AppBar, Toolbar, Grid, IconButton } from '@material-ui/core'
import './SandHeader.scss'

class SandHeader extends Component {
	render() {
		const { user } = this.props

		return (
			<Grid>
				<AppBar className="SAppBar" color="default" position="static">
					<Toolbar eventkey={0} isopen={user} className="navbarItem">
						<IconButton href="/" className="brand" color="inherit" aria-label="Logo" />
						<div className="nav__icons">
							{/* <div className="lang-selection">
                <Select
                  className="lang-select"
                  value={i18nState.lang}
                  onChange={event =>
                    this.props.dispatch(setLanguage(event.target.value))
                  }
                  renderValue={value => (
                    <Grid container alignItems="center" justify="center">
                      <img src={flags[value]} alt={value} /> &nbsp;&nbsp;
                      {value.toUpperCase()}
                    </Grid>
                  )}
                  input={<OutlinedInput />}
                >
                  <MenuItem value="es">ES</MenuItem>
                  <MenuItem value="en">EN</MenuItem>
                </Select>
              </div> */}
							{/* {user && user.isVerified && (
                <>
                  <div className="header-tools">
                    <AnouncementButton />
                    <a href={faqLink} target="_blank" rel="noopener noreferrer">
                      <Tooltip title="FAQ">
                        <IconButton>
                          <img
                            src={FaqIcon}
                            alt=""
                            className="anouncement-icon"
                          />
                        </IconButton>
                      </Tooltip>
                    </a>
                    <NotificationButton />
                  </div>
                  <div className="profile__but" eventkey={6}>
                    <Button href="/setting" className="btn-name">
                      <Picture src={getAvatarPath(user.img) || DefaultAvatar} />
                      &nbsp; &nbsp;
                      <Typography noWrap>
                        <strong>
                          {this.context.t('hi-{name}', {
                            name: user.firstname,
                          })}
                        </strong>
                      </Typography>
                      !
                    </Button>
                  </div>
                </>
              )} */}
							{(!user || !user.isVerified) && (
								<Link to="/login-talent">
									<Button className="register_but" eventkey={6}>
										{this.context.t('register-login')}
									</Button>
								</Link>
							)}
						</div>
					</Toolbar>
				</AppBar>
				{/* <Grid container className="app-hero">
          {this.getHeader(history.location.pathname)}
        </Grid> */}
			</Grid>
		)
	}
}

SandHeader.contextTypes = {
	t: PropTypes.func
}

SandHeader.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	user: PropTypes.object,
	history: PropTypes.object.isRequired,
	i18nState: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
	isLoggedIn: state.authReducer.isLoggedIn,
	user: state.authReducer.user,
	i18nState: state.i18nState
})

export default withRouter(connect(mapStateToProps, null)(SandHeader))

const actions = {
	GET_CHAPTER_REQUEST: 'GET_CHAPTER_REQUEST',
	GET_CHAPTER_SUCCESS: 'GET_CHAPTER_SUCCESS',
	GET_CHAPTER_FAILED: 'GET_CHAPTER_FAILED',

	GET_SCHAPTER_RESULT_REQUEST: 'GET_SCHAPTER_RESULT_REQUEST',
	GET_SCHAPTER_RESULT_SUCCESS: 'GET_SCHAPTER_RESULT_SUCCESS',
	GET_SCHAPTER_RESULT_FAILED: 'GET_SCHAPTER_RESULT_FAILED',

	SET_CHAPTER_RESULT_REQUEST: 'SET_CHAPTER_RESULT_REQUEST',
	SET_CHAPTER_RESULT_SUCCESS: 'SET_CHAPTER_RESULT_SUCCESS',
	SET_CHAPTER_RESULT_FAILED: 'SET_CHAPTER_RESULT_FAILED',

	UPDATE_CHAPTER_RESULT_REQUEST: 'UPDATE_CHAPTER_RESULT_REQUEST',
	UPDATE_CHAPTER_RESULT_SUCCESS: 'UPDATE_CHAPTER_RESULT_SUCCESS',
	UPDATE_CHAPTER_RESULT_FAILED: 'UPDATE_CHAPTER_RESULT_FAILED',

	GET_USER_SKILL_VALUES_REQUEST: 'GET_USER_SKILL_VALUES_REQUEST',
	GET_USER_SKILL_VALUES_SUCCESS: 'GET_USER_SKILL_VALUES_SUCCESS',
	GET_USER_SKILL_VALUES_FAILED: 'GET_USER_SKILL_VALUES_FAILED',

	getChapter: payload => ({
		type: actions.GET_CHAPTER_REQUEST,
		payload
	}),
	getChapterSuccess: payload => ({
		type: actions.GET_CHAPTER_SUCCESS,
		payload
	}),
	getChapterFailed: payload => ({
		type: actions.GET_CHAPTER_FAILED,
		payload
	}),

	getSChapterResult: payload => ({
		type: actions.GET_SCHAPTER_RESULT_REQUEST,
		payload
	}),
	getSChapterResultSuccess: payload => ({
		type: actions.GET_SCHAPTER_RESULT_SUCCESS,
		payload
	}),
	getSChapterResultFailed: payload => ({
		type: actions.GET_SCHAPTER_RESULT_FAILED,
		payload
	}),

	getUserSkillValues: payload => ({
		type: actions.GET_USER_SKILL_VALUES_REQUEST,
		payload
	}),
	getUserSkillValuesSuccess: payload => ({
		type: actions.GET_USER_SKILL_VALUES_SUCCESS,
		payload
	}),
	getUserSkillValuesFailed: payload => ({
		type: actions.GET_USER_SKILL_VALUES_FAILED,
		payload
	})
}

export default actions

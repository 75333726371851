import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import refType from '../refType'
import InputBase from 'material-ui-next/InputBase'
import NotchedOutline from './NotchedOutline'
import withStyles from 'material-ui-next/styles/withStyles'

export const styles = theme => {
	const borderColor =
		theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'

	return {
		/* Styles applied to the root element. */
		root: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			'&:hover $notchedOutline': {
				borderColor: theme.palette.text.primary
			},
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				'&:hover $notchedOutline': {
					borderColor
				}
			},
			'&$focused $notchedOutline': {
				borderColor: theme.palette.primary.main,
				borderWidth: 2
			},
			'&$error $notchedOutline': {
				borderColor: theme.palette.error.main
			},
			'&$disabled $notchedOutline': {
				borderColor: theme.palette.action.disabled
			}
		},
		/* Styles applied to the root element if the color is secondary. */
		colorSecondary: {
			'&$focused $notchedOutline': {
				borderColor: theme.palette.secondary.main
			}
		},
		/* Styles applied to the root element if the component is focused. */
		focused: {},
		/* Styles applied to the root element if `disabled={true}`. */
		disabled: {},
		/* Styles applied to the root element if `startAdornment` is provided. */
		adornedStart: {
			paddingLeft: 14
		},
		/* Styles applied to the root element if `endAdornment` is provided. */
		adornedEnd: {
			paddingRight: 14
		},
		/* Pseudo-class applied to the root element if `error={true}`. */
		error: {},
		/* Styles applied to the `input` element if `margin="dense"`. */
		marginDense: {},
		/* Styles applied to the root element if `multiline={true}`. */
		multiline: {
			padding: '18.5px 14px',
			'&$marginDense': {
				paddingTop: 10.5,
				paddingBottom: 10.5
			}
		},
		/* Styles applied to the `NotchedOutline` element. */
		notchedOutline: {
			borderColor
		},
		/* Styles applied to the `input` element. */
		input: {
			padding: '18.5px 14px',
			'&:-webkit-autofill': {
				WebkitBoxShadow:
					theme.palette.type === 'light' ? null : '0 0 0 100px #266798 inset',
				WebkitTextFillColor: theme.palette.type === 'light' ? null : '#fff',
				caretColor: theme.palette.type === 'light' ? null : '#fff',
				borderRadius: 'inherit'
			}
		},
		/* Styles applied to the `input` element if `margin="dense"`. */
		inputMarginDense: {
			paddingTop: 10.5,
			paddingBottom: 10.5
		},
		/* Styles applied to the `input` element if `multiline={true}`. */
		inputMultiline: {
			padding: 0
		},
		/* Styles applied to the `input` element if `startAdornment` is provided. */
		inputAdornedStart: {
			paddingLeft: 0
		},
		/* Styles applied to the `input` element if `endAdornment` is provided. */
		inputAdornedEnd: {
			paddingRight: 0
		}
	}
}

const OutlinedInput = React.forwardRef((props, ref) => {
	const {
		classes,
		fullWidth = false,
		inputComponent = 'input',
		label,
		labelWidth = 0,
		multiline = false,
		notched,
		type = 'text',
		...other
	} = props

	return (
		<InputBase
			renderSuffix={state => (
				<NotchedOutline
					className={classes.notchedOutline}
					label={label}
					labelWidth={labelWidth}
					notched={
						typeof notched !== 'undefined'
							? notched
							: Boolean(state.startAdornment || state.filled || state.focused)
					}
				/>
			)}
			classes={{
				...classes,
				root: clsx(classes.root, classes.underline),
				notchedOutline: null
			}}
			fullWidth={fullWidth}
			inputComponent={inputComponent}
			multiline={multiline}
			ref={ref}
			type={type}
			{...other}
		/>
	)
})

OutlinedInput.propTypes = {
	// ----------------------------- Warning --------------------------------
	// | These PropTypes are generated from the TypeScript type definitions |
	// |     To update them edit the d.ts file and run "yarn proptypes"     |
	// ----------------------------------------------------------------------
	/**
	 * This prop helps users to fill forms faster, especially on mobile devices.
	 * The name can be confusing, as it's more like an autofill.
	 * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
	 */
	autoComplete: PropTypes.string,
	/**
	 * If `true`, the `input` element will be focused during the first mount.
	 */
	autoFocus: PropTypes.bool,
	/**
	 * Override or extend the styles applied to the component.
	 * See [CSS API](#css) below for more details.
	 */
	classes: PropTypes.object,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 */
	color: PropTypes.oneOf(['primary', 'secondary']),
	/**
	 * The default `input` element value. Use when the component is not controlled.
	 */
	defaultValue: PropTypes.any,
	/**
	 * If `true`, the `input` element will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * End `InputAdornment` for this component.
	 */
	endAdornment: PropTypes.node,
	/**
	 * If `true`, the input will indicate an error. This is normally obtained via context from
	 * FormControl.
	 */
	error: PropTypes.bool,
	/**
	 * If `true`, the input will take up the full width of its container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * The id of the `input` element.
	 */
	id: PropTypes.string,
	/**
	 * The component used for the `input` element.
	 * Either a string to use a HTML element or a component.
	 */
	inputComponent: PropTypes.elementType,
	/**
	 * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
	 */
	inputProps: PropTypes.object,
	/**
	 * Pass a ref to the `input` element.
	 */
	inputRef: refType,
	/**
	 * The label of the input. It is only used for layout. The actual labelling
	 * is handled by `InputLabel`. If specified `labelWidth` is ignored.
	 */
	label: PropTypes.node,
	/**
	 * The width of the label. Is ignored if `label` is provided. Prefer `label`
	 * if the input label appears with a strike through.
	 */
	labelWidth: PropTypes.number,
	/**
	 * If `dense`, will adjust vertical spacing. This is normally obtained via context from
	 * FormControl.
	 */
	margin: PropTypes.oneOf(['dense', 'none']),
	/**
	 * If `true`, a textarea element will be rendered.
	 */
	multiline: PropTypes.bool,
	/**
	 * Name attribute of the `input` element.
	 */
	name: PropTypes.string,
	/**
	 * If `true`, the outline is notched to accommodate the label.
	 */
	notched: PropTypes.bool,
	/**
	 * Callback fired when the value is changed.
	 *
	 * @param {object} event The event source of the callback.
	 * You can pull out the new value by accessing `event.target.value` (string).
	 */
	onChange: PropTypes.func,
	/**
	 * The short hint displayed in the input before the user enters a value.
	 */
	placeholder: PropTypes.string,
	/**
	 * It prevents the user from changing the value of the field
	 * (not from interacting with the field).
	 */
	readOnly: PropTypes.bool,
	/**
	 * If `true`, the `input` element will be required.
	 */
	required: PropTypes.bool,
	/**
	 * Number of rows to display when multiline option is set to true.
	 */
	rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * Maximum number of rows to display when multiline option is set to true.
	 */
	rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * Start `InputAdornment` for this component.
	 */
	startAdornment: PropTypes.node,
	/**
	 * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
	 */
	type: PropTypes.string,
	/**
	 * The value of the `input` element, required for a controlled component.
	 */
	value: PropTypes.any
}

OutlinedInput.muiName = 'Input'

export default withStyles(styles, { name: 'MuiOutlinedInput2' })(OutlinedInput)

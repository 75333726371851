import { all } from 'redux-saga/effects'
import adminSagas from './admin/saga'
import authSagas from './auth/saga'
import resultSagas from './result/saga'
import sandboxSagas from './sandbox/saga'
import categorySagas from './category/saga'
import chapterSagas from './chapter/saga'

export default function* rootSaga(getState) {
	yield all([
		adminSagas(),
		authSagas(),
		resultSagas(),
		sandboxSagas(),
		categorySagas(),
		chapterSagas()
	])
}

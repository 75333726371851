import React, { useState, useEffect, useMemo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import axios from 'axios'
import iziToast from 'izitoast'
import clsx from 'clsx'
import Autocomplete from '../../Autocomplete'
import TextField from '../../TextField'
import BaseModal from '../BaseModal'
import Picture from '../../AvatarMini'
import defaultAvatar from '../../../images/default-character.png'
import { getAvatarPath } from '../../../utils/userUtil'
import { getEndpoint } from '../../../utils/urlHelper'
import { ANALYTICS_URL } from '../../../constants/config'

const CustomInput = withStyles(theme => ({
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #d3d3d3',
		fontSize: 14,
		width: '100%',
		padding: '15px 26px 15px 12px',
		lineHeight: '1.43',
		letterSpacing: 'normal',
		'&:focus': {
			borderRadius: 4,
			borderColor: theme.palette.primary.main
		}
	}
}))(TextField)

const styles = {
	root: {
		textAlign: 'center',
		color: '#4a4a4a'
	},
	modal: {
		padding: 20,
		paddingBottom: 20
	},
	button: {
		textTransform: 'none'
	},
	container: {
		width: 621,
		maxWidth: '95vw'
	},
	avatar: {
		width: 80,
		height: 80
	},
	title: {
		marginBottom: 20
	},
	firstName: {
		marginTop: 5
	}
}

const Link = styled.a`
	color: #4a4a4a;
	text-decoration: underline !important;
`

const initialState = {
	category: null,
	pipeline: null
}

const AddToPipeline = ({ open, onClose, classes, user, token }) => {
	const [state, setState] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const [okLoading, setOkLoading] = useState(false)
	const [pipelines, setPipelines] = useState([])
	const { baseCategories, chapterResults } = useSelector(store => ({
		baseCategories: store.categoryReducer.categories,
		chapterResults: store.sandboxReducer.sChapterResult
	}))
	const categories = useMemo(() => {
		if (!chapterResults || !baseCategories) return []
		const chapters = [...new Set(chapterResults.map(result => result.chapter_id._id))]
		return baseCategories.filter(category =>
			category.challenge_list.some(challenge =>
				challenge.chapter_list.some(chapter => chapters.indexOf(chapter._id) !== -1)
			)
		)
	}, [chapterResults, baseCategories])
	const sandboxUser = useSelector(store => store.sandboxReducer.sand_data)
	const handleChange = key => e => {
		if (typeof e !== 'object' || Array.isArray(e) || !e) {
			setState({
				...state,
				[key]: e
			})
		} else if (typeof e?.target?.value !== 'undefined') {
			setState({
				...state,
				[key]: e.target.value
			})
		} else if (typeof e === 'object') {
			setState({
				...state,
				[key]: e
			})
		}
	}
	useEffect(() => {
		if (state.pipeline?.category_id !== state.category?._id && state.pipeline !== null) {
			setState({
				...state,
				category: categories.find(category => category._id === state.pipeline?.category_id)
			})
		}
		// eslint-disable-next-line
	}, [state.pipeline])
	useEffect(() => {
		if (state.pipeline?.category_id !== state.category?._id) {
			setState({
				...state,
				pipeline: null
			})
		}
		// eslint-disable-next-line
	}, [state.category])
	const getPipelines = async () => {
		try {
			setLoading(true)
			const { data } = await axios.get(getEndpoint('pipeline/user'), {
				params: {
					category: state.category?._id
				},
				headers: {
					Authorization: token
				}
			})
			setPipelines(data.data)
		} catch (error) {
			if (error?.response?.data?.msg) {
				iziToast.error({
					title: 'Error',
					message: error.response.data.msg
				})
			}
		} finally {
			setLoading(false)
		}
	}
	const addToPipeline = async () => {
		try {
			setOkLoading(true)
			const { data } = await axios.post(
				getEndpoint(`pipeline/${state.pipeline._id}/users/${sandboxUser.uuid}`),
				{},
				{
					headers: {
						Authorization: token
					}
				}
			)
			if (data.success) {
				iziToast.success({
					title: 'Success',
					message: data.msg
				})
				onClose()
			}
		} catch (error) {
			if (error?.response?.data?.msg) {
				iziToast.error({
					title: 'Error',
					message: error.response.data.msg
				})
			}
		} finally {
			setOkLoading(false)
		}
	}
	return (
		<BaseModal
			open={open}
			onClose={onClose}
			className={classes.container}
			classes={{
				button: classes.button,
				content: classes.root
			}}
			onOk={addToPipeline}
			customOnOk
			okLoading={okLoading}
			okButtonProps={{
				disabled: !(state.category && state.pipeline)
			}}
			title="Add to pipeline"
			okText="Add to pipeline"
		>
			<div>
				<Picture
					src={getAvatarPath(user.img) || defaultAvatar}
					classes={{ avatar: classes.avatar }}
					alt={user.firstname}
				/>
				<Typography
					variant="h5"
					className={clsx(classes.root, classes.firstName)}
					gutterBottom
				>
					{user.firstname}
				</Typography>
				<small>
					<i>
						Not your account?{' '}
						<Link
							href={`${ANALYTICS_URL}/logout?redirectTo=${window.location.href}&sandbox=true`}
							target="_self"
							rel="noopener noreferrer"
						>
							Sign in
						</Link>
					</i>
				</small>
			</div>
			<Grid container spacing={24} alignItems="center" style={{ marginTop: 20 }}>
				<Grid item container xs={12} justify="center">
					<Grid item xs={12} md={7}>
						<Autocomplete
							fullWidth
							autoHighlight
							options={categories}
							getOptionLabel={option => option.name}
							value={state.category}
							onChange={(_e, obj) => {
								handleChange('category')(obj)
							}}
							renderInput={params => (
								<CustomInput
									{...params}
									variant="outlined"
									placeholder="Select a challenges group"
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid item container xs={12} justify="center">
					<Grid item xs={12} md={7}>
						<Autocomplete
							fullWidth
							autoHighlight
							multiple={false}
							onOpen={getPipelines}
							loading={loading}
							options={pipelines}
							getOptionLabel={option => option.title}
							value={state.pipeline}
							onChange={(_e, obj) => {
								handleChange('pipeline')(obj)
							}}
							renderInput={params => (
								<CustomInput
									{...params}
									variant="outlined"
									placeholder="Select a pipeline"
									{...(!state.category
										? { helperText: 'Click here to show all your pipelines' }
										: {})}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading && (
													<i
														className="bx bx-loader-alt bx-spin"
														style={{ color: 'black', marginRight: -20 }}
													/>
												)}
												{params.InputProps.endAdornment}
											</>
										)
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</BaseModal>
	)
}

export default withStyles(styles)(AddToPipeline)

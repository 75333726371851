import actions from './actions'
import { getUser, saveUser } from '../../utils/localStorageUtil'

const initState = {
	challenges: null,
	detailData: null,
	sChapterResult: null,
	curChallenge: null,
	isLoading: false,
	registerTStatus: false,
	loginTStatus: false,
	loginInfo: {},
	errorMsg: null,
	getUserError: null,
	resultSendMail: false,
	runBadge: false,
	success: false,
	registerPIResult: false,
	justRegistered: false,
	sandUserData: getUser(),
	sand_data: getUser(),
	marketCards: [],
	loadingMarketCards: false,
	ppRegResult: null,
	coins: 0,
	useremail: null,
	loading: false,
	coinsLoading: false
}

export default function sandboxReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.REGISTER_SAND_REQUEST:
			return {
				...state,
				sand_user: null,
				registerTStatus: false,
				errorMsg: null
			}

		case actions.REGISTER_SAND_SUCCESS:
			return {
				...state,
				sand_user: payload,
				registerTStatus: true,
				errorMsg: null
			}

		case actions.REGISTER_SAND_FAILED:
			return {
				...state,
				sand_user: null,
				registerTStatus: false,
				errorMsg: payload
			}

		case actions.LOGIN_SAND_REQUEST:
			return {
				...state,
				loginTStatus: false,
				user: null,
				errorMsg: null
			}

		case actions.LOGIN_SAND_SUCCESS:
			return {
				...state,
				loginTStatus: true,
				loginInfo: payload,
				user: payload,
				errorMsg: null
			}

		case actions.LOGIN_SAND_FAILED:
			return {
				...state,
				loginTStatus: false,
				user: null,
				errorMsg: payload
			}

		case actions.OAUTH_SAND_TOKEN:
			return {
				...state,
				sand_data: null,
				loginTStatus: true,
				loginInfo: null,
				errorMsg: null
			}

		case actions.LOGOUT_SAND_REQUEST:
			return {
				...state,
				errorMsg: null
			}

		case actions.LOGOUT_SAND_SUCCESS:
			return {
				...state,
				sand_data: null,
				loginTStatus: false,
				loginInfo: null,
				errorMsg: null
			}
		// case actions.SET_BADGE_NOTIFICATION_REQUEST:
		// 	return state
		case actions.LOGOUT_SAND_FAILED:
			return {
				...state,
				loginTStatus: false,
				errorMsg: null
			}

		case actions.SET_BADGE_NOTIFICATION_SUCCESS:
			const index = state.sand_data.badges.findIndex(({ _id }) => _id === payload)
			const badges = [...state.sand_data.badges]
			badges[index].notifications = false
			return {
				...state,
				sand_data: {
					...state.sand_data,
					badges
				}
			}
		case actions.SET_ALL_BADGE_NOTIFICATION_SUCCESS:
			return {
				...state,
				sand_data: {
					...state.sand_data,
					badges: [...state.sand_data.badges].map(val => ({
						...val,
						notifications: false
					}))
				}
			}
		case actions.GET_SANDDATA_REQUEST:
			return {
				...state,
				getTStatus: false
				// sand_data: null
			}

		case actions.GET_SANDDATA_SUCCESS:
			saveUser(payload)
			return {
				...state,
				getTStatus: true,
				sand_data: payload
			}

		case actions.GET_SANDDATA_FAILED:
			return {
				...state,
				getTStatus: false,
				sand_data: null
			}

		case actions.GET_SANDDATA_PROFILE_REQUEST:
			return {
				...state,
				getTStatus: false,
				sand_data: null
			}

		case actions.GET_SANDDATA_PROFILE_SUCCESS:
			return {
				...state,
				getTStatus: true,
				sand_data: payload
			}

		case actions.GET_SANDDATA_PROFILE_FAILED:
			return {
				...state,
				getTStatus: false,
				getUserError: payload,
				sand_data: null
			}

		case actions.GET_ECHAPTER_RESULT_REQUEST:
			return {
				...state,
				errorMsg: null,
				sChapterResult: null
			}
		case actions.GET_ECHAPTER_RESULT_SUCCESS:
			return {
				...state,
				sChapterResult: payload
			}
		case actions.GET_ECHAPTER_RESULT_FAILED:
			return {
				...state,
				errorMsg: payload,
				sChapterResult: null
			}

		case actions.GET_ALL_CHALLENGE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				challenges: null
			}

		case actions.GET_ALL_CHALLENGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				challenges: payload
			}

		case actions.GET_ALL_CHALLENGE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload,
				challenges: null
			}

		case actions.GET_CHALLENGE_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				curChallenge: null
			}

		case actions.GET_CHALLENGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				curChallenge: payload
			}

		case actions.GET_CHALLENGE_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload,
				curChallenge: null
			}

		case actions.SET_PROFILE_INFO_REQUEST:
			return {
				...state,
				errorMsg: payload,
				registerPIResult: false,
				sandUserData: null,
				justRegistered: false
			}

		case actions.SET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				sandUserData: payload,
				sand_data: {
					...state.sand_data,
					...payload
				},
				registerPIResult: true,
				justRegistered: true,
				errorMsg: null
			}

		case actions.SET_PROFILE_INFO_FAILED:
			return {
				...state,
				errorMsg: payload,
				registerPIResult: false,
				sandUserData: null,
				justRegistered: false
			}

		case actions.UPDATE_PROFILE_INFO_REQUEST:
			saveUser({
				...state.sand_data,
				...payload
			})
			return {
				...state,
				sand_data: {
					...state.sand_data,
					...payload
				}
			}

		case actions.UPDATE_PROFILE_INFO_SUCCESS:
			return {
				...state,
				sandUserData: payload,
				sand_data: {
					...state.sand_data,
					...payload
				},
				detailData: payload
			}

		// case actions.UPDATE_PROFILE_INFO_FAILED:
		// 	return {
		// 		...state,
		// 		errorMsg: payload,
		// 		detailData: null
		// 	}

		case actions.UPDATE_SANDBOX_RECORD_REQUEST:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_RECORD_SUCCESS:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_RECORD_FAILED:
			return {
				...state,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_REQUEST:
			return {
				...state,
				ppRegResult: null,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_SUCCESS:
			return {
				...state,
				sand_data: {
					...state.sand_data,
					public_profile: payload
				},
				sandUserData: {
					...state.sandUserData,
					public_profile: payload
				},
				ppRegResult: true,
				errorMsg: null
			}

		case actions.UPDATE_SANDBOX_PPROFILE_FAILED:
			return {
				...state,
				ppRegResult: null,
				errorMsg: null
			}

		case actions.GET_PROFILE_INFO_REQUEST:
			return {
				...state,
				errorMsg: null,
				detailData: null
			}

		case actions.GET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				detailData: payload,
				errorMsg: null
			}

		case actions.GET_PROFILE_INFO_FAILED:
			return {
				...state,
				errorMsg: null,
				detailData: null
			}

		case actions.SET_PD_QUESTIONNAIRE_REQUEST:
			return {
				...state,
				registerQuestion: false,
				errorMsg: null
			}

		case actions.SET_PD_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				registerQuestion: true,
				errorMsg: null
			}

		case actions.SET_PD_QUESTIONNAIRE_FAILED:
			return {
				...state,
				registerQuestion: false,
				errorMsg: null
			}

		case actions.SEND_VERIFY_REQUEST:
			return {
				...state,
				resultSendMail: false,
				errorMsg: null
			}

		case actions.SEND_VERIFY_REQUEST_SUCCESS:
			return {
				...state,
				resultSendMail: payload,
				errorMsg: null
			}

		case actions.SEND_VERIFY_REQUEST_FAILED:
			return {
				...state,
				resultSendMail: payload,
				errorMsg: payload
			}

		case actions.VALIDATE_SAND_USER:
			return {
				...state,
				isLoading: true
			}

		case actions.VALIDATE_SAND_USER_SUCCESS:
			return {
				...state,
				isLoading: true,
				user: payload
			}

		case actions.CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: false,
				success: false,
				useremail: null
			}

		case actions.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
				success: true,
				useremail: payload
			}

		case actions.CHANGE_PASSWORD_FAILED:
			return {
				...state,
				errorMsg: payload,
				isLoading: false,
				success: false,
				useremail: null
			}
		case actions.ERASE_ERROR_MSG:
			return {
				...state,
				errorMsg: null
			}

		case actions.GET_SANDBOX_COINS_REQUEST:
			return {
				...state,
				coins: 0,
				isLoading: false,
				coinsLoading: true,
				success: false
			}

		case actions.GET_SANDBOX_COINS_SUCCESS:
			return {
				...state,
				coins: payload,
				coinsLoading: false,
				success: true
			}

		case actions.GET_SANDBOX_COINS_FAILED:
			return {
				...state,
				coins: 0,
				coinsLoading: false,
				success: false
			}
		case actions.GET_MARKET_CARDS_REQUEST:
			return {
				...state,
				loadingMarketCards: true
			}
		case actions.GET_MARKET_CARDS_SUCCESS:
			return {
				...state,
				marketCards: payload,
				loadingMarketCards: false
			}
		case actions.GET_MARKET_CARDS_FAILED:
			return {
				...state,
				loadingMarketCards: false
			}
		case actions.BUY_MARKET_CARD_SUCCESS:
			return {
				...state,
				marketCards: state.marketCards.map(card => {
					if (card.id === payload.id) {
						return {
							...card,
							redeemed: true
						}
					}
					return card
				}),
				coins: payload.coins
			}
		case actions.UPDATE_COINS:
			return {
				...state,
				coins: payload
			}

		case actions.SAVE_BADGE_VALUE_REQUEST:
			return {
				...state,
				isLoading: false,
				runBadge: true,
				saveBadge: false
			}

		case actions.SAVE_BADGE_VALUE_SUCCESS:
			return {
				...state,
				isLoading: false,
				sand_data: {
					...state.sand_data,
					badges: payload.values
				}
			}

		case actions.SAVE_BADGE_VALUE_FAILED:
			return {
				...state,
				isLoading: false,
				saveBadge: false
			}

		case actions.GET_BADGE_VALUE_REQUEST:
			return {
				...state,
				isLoading: false,
				badges: null
			}

		case actions.GET_BADGE_VALUE_SUCCESS:
			return {
				...state,
				isLoading: false,
				badges: payload
			}

		case actions.GET_BADGE_VALUE_FAILED:
			return {
				...state,
				isLoading: false,
				badges: null
			}

		case actions.PASSWORD_RESET_EMAIL_REQUEST:
			return {
				...state,
				loading: false,
				errorMsg: null
			}
		case actions.PASSWORD_RESET_EMAIL_SUCCESS:
			return {
				...state,
				loading: true,
				errorMsg: null
			}
		case actions.PASSWORD_RESET_EMAIL_FAILED:
			return {
				...state,
				loading: false,
				errorMsg: payload
			}

		default:
			return state
	}
}

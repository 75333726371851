import actions from './actions'

const initState = {
	chapter: {},
	chapters: [],
	chapterResult: null,
	isLoading: false,
	errorMsg: null,
	updateStatus: false,
	skillValues: null
}

export default function challengeReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.GET_CHAPTER_REQUEST:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_CHAPTER_SUCCESS:
			return {
				...state,
				isLoading: true,
				chapter: payload
			}

		case actions.GET_CHAPTER_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			}

		case actions.GET_SCHAPTER_RESULT_REQUEST:
			return {
				...state,
				isLoading: false
			}
		case actions.GET_SCHAPTER_RESULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				chapterResult: payload
			}
		case actions.GET_SCHAPTER_RESULT_FAILED:
			return {
				...state,
				isLoading: false
			}

		case actions.GET_USER_SKILL_VALUES_REQUEST:
			return {
				...state,
				isLoading: false
			}
		case actions.GET_USER_SKILL_VALUES_SUCCESS:
			return {
				...state,
				skillValues: payload,
				isLoading: false
			}
		case actions.GET_USER_SKILL_VALUES_FAILED:
			return {
				...state,
				isLoading: false
			}

		default:
			return state
	}
}

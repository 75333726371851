import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Paper, Divider, Grid } from '@material-ui/core'
import moment from 'moment'
import { ReactComponent as BadgeIcon } from '../../../images/icons/badge.svg'
import './BadgeModal.scss'

function getModalStyle() {
	const top = 50
	const left = 50

	return {
		position: 'absolute',
		top: `${45}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
		// maxHeight: window.innerHeight - 160
	}
}

const BadgeModal = ({ badge, show, handleClose }) => {
	return (
		<Modal className="badge-modal" open={show} onClose={handleClose}>
			<div>
				<Paper className="paper-main" elevation={0} style={getModalStyle()}>
					<div className="paper-container">
						<div className="badge-title">{badge.name}</div>
						<Divider className="divider" />
						<div className="badge-content">{badge.desc}</div>
						{badge.isEarned && (
							<>
								<div className="badge-content-item">
									<div className="top-part">
										{badge.categories &&
											badge.categories.map(e => {
												return (
													<div
														key={e._id}
														className="top-part-item"
														style={{ background: e.color }}
													/>
												)
											})}
									</div>
									<div
										className={`badge-item-icon ${badge.isEarned && 'earned'}`}
										style={{
											background: badge.isEarned ? badge.gradient : '#DFE1E6'
										}}
									>
										<BadgeIcon fill={badge.isEarned ? '#fff' : '#9FA9BA'} />
									</div>
								</div>
								<div className="badge-categories">
									<Grid container spacing={24}>
										{badge.categories.map(category => (
											<Grid key={category._id} item xs={12} sm={12} md={6} lg={3}>
												<div className="category-item">
													<div className="category-container">
														<span
															className="category-color"
															style={{ backgroundColor: category.color }}
														/>
														<span>{category.name}</span>
													</div>
													<small>
														Earned on {moment(category.updatedAt).format('MM/DD/YYYY')}
													</small>
												</div>
											</Grid>
										))}
									</Grid>
								</div>
							</>
						)}
					</div>
				</Paper>
			</div>
		</Modal>
	)
}

BadgeModal.propTypes = {
	badge: PropTypes.shape({
		_id: PropTypes.string,
		name: PropTypes.string,
		desc: PropTypes.string,
		color: PropTypes.string,
		gradient: PropTypes.string,
		isEarned: PropTypes.bool,
		updatedAt: PropTypes.instanceOf(Date),
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				_id: PropTypes.string,
				name: PropTypes.string,
				color: PropTypes.string,
				updatedAt: PropTypes.string
			})
		)
	}).isRequired,
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func
}

export default BadgeModal

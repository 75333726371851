import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Paper } from '@material-ui/core'
import data from './data'
import './OverfitModal.scss'

class OverfitModal extends React.Component {
	constructor(props) {
		super(props)
		this.handleClose = this.handleClose.bind(this)
		this.state = {}
	}

	handleClose() {
		this.props.onHide()
	}

	render() {
		const { show, title, sValue } = this.props

		let range_text = ''
		if (sValue >= 0 && sValue < 65) {
			range_text = '0 - 64%. '
		} else if (sValue >= 65 && sValue < 80) {
			range_text = '65 - 79%. '
		} else if (sValue >= 80) {
			range_text = '80 - 100%. '
		}

		return (
			<Modal className="fit-modal" open={show} onClose={this.handleClose}>
				<div>
					<Paper className="paper-main" elevation={0}>
						{/* <div className="fit-title">{this.context.t(title)}</div>
            <Divider className="divider" />
            <div className="fit-content">{data[title]}</div> */}

						<div className="fit-title">{this.context.t('target-fit')}</div>
						<div className="fit-content">Your score is 80 - 100%. {data['target-fit']}</div>

						<div className="fit-title">{this.context.t('close-fit')}</div>
						<div className="fit-content">Your score is 65 - 79%. {data['close-fit']}</div>

						<div className="fit-title">{this.context.t('complement-fit')}</div>
						<div className="fit-content">
							Your score is 0 - 64%. {data['complement-fit']}
						</div>
					</Paper>
				</div>
			</Modal>
		)
	}
}

OverfitModal.contextTypes = {
	t: PropTypes.func
}

OverfitModal.propTypes = {
	title: PropTypes.string,
	onHide: PropTypes.func,
	show: PropTypes.bool
}

export default OverfitModal

import React from 'react'
import svg from '../../images/tail-spin.svg'

const Loading = () => (
	<div className="svg-loader">
		<img src={svg} alt="loader" />
	</div>
)

export default Loading

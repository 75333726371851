import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import actions from './actions'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

export function* getChapter() {
	yield takeEvery(actions.GET_CHAPTER_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`chapter/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getChapterSuccess(res.data.data))
		} catch (err) {
			yield put(actions.getChapterFailed(err))
		}
	})
}

export function* getSChapterResult() {
	yield takeEvery(actions.GET_SCHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`chapter/getResult/${payload}`),
			method: 'get',
			headers: getHeaders()
		}
		try {
			const result = yield call(axios.request, params)
			let data = result.data.chapterResult
			yield put(actions.getSChapterResultSuccess(data))
		} catch (err) {
			yield put(actions.getSChapterResultFailed(err))
		}
	})
}

export function* getUserSValues() {
	yield takeEvery(actions.GET_USER_SKILL_VALUES_REQUEST, function* ({ payload }) {
		const { categoryId, userUUId } = payload
		const params = {
			url: getEndpoint(`getSValue/${categoryId}/${userUUId}`),
			method: 'get',
			headers: getHeaders()
		}
		try {
			const result = yield call(axios.request, params)
			let data = result.data.data
			yield put(actions.getUserSkillValuesSuccess(data))
		} catch (err) {
			yield put(actions.getUserSkillValuesFailed(err))
		}
	})
}

export default function* chapterSagas() {
	yield all([fork(getChapter), fork(getSChapterResult), fork(getUserSValues)])
}

import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

export default createMuiTheme({
	palette: {
		primary: {
			main: '#e1628d'
		},
		secondary: {
			main: '#928ef3'
		},
		white: {
			main: '#fff'
		},
		auth: '#928ef3',
		danger: red

		// primary: '#e1628d',
		// secondary: '#928ef3',
		// tertiary: '#7D6EF1',
		// error: '#ff3349',
		// text: '#202020',
		// text_link: '#928ef3',
		// disabled: '#636669',
		// caption: '#9fa9ba'
	},
	typography: {
		fontFamily: 'sans-serif',
		fontSize: 14,
		fontWeight: 500,
		useNextVariants: true
	}
})

import React, { Component } from 'react'
import { Chip, Typography } from '@material-ui/core'
import Highcharts from 'highcharts'
import PropTypes from 'prop-types'
import HighchartsMore from 'highcharts/highcharts-more'
// import { cloneDeep as _cloneDeep, range as _range } from 'lodash';
import HighchartsReact from 'highcharts-react-official'
import './Charts.scss'

export { BubbleChart } from './BubbleChart'

export class StackedBarChart extends Component {
	render() {
		const { chartdata } = this.props
		const categories_len = chartdata.categories && chartdata.categories.length
		const options = {
			colors: chartdata.colors,
			chart: {
				type: 'bar',
				height: categories_len * 10 + (categories_len - 1) * 30 + 100
			},
			title: {
				text: ''
			},
			xAxis: {
				lineWidth: 0,
				categories: chartdata.categories,
				labels: {
					useHTML: true,
					format: '<div class="bar-label">{value}</div>',
					padding: 20
				},
				gridLineWidth: 0
			},
			yAxis: {
				gridLineWidth: 0,
				visible: false,
				title: {
					text: ''
				}
			},
			legend: {
				reversed: true
				// layout: 'vertical',
				// align: 'right',
				// verticalAlign: 'top',
				// x: -40,
				// y: 80,
				// floating: true,
				// borderWidth: 1,
				// backgroundColor:
				//     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
				// shadow: true
			},
			plotOptions: {
				// series: {
				//   stacking: 'overlap',
				//   pointWidth: 10,
				// },
				bar: {
					borderRadius: 4.5,
					pointWidth: 10,
					dataLabels: {
						// enabled: true
					}
				}
				// bar: {
				//   pointPadding: 0,
				//   borderWidth: 0,
				//   groupPadding: 0.01
				// },
			},
			tooltip: {
				// positioner: function(labelWidth, labelHeight, point) {
				//   return {
				//     x: point.plotX + 20,
				//     y: point.plotY - 35
				//   };
				// },
				formatter() {
					return `${this.y}%`
				}
			},
			series: chartdata.series
		}

		return <HighchartsReact highcharts={Highcharts} options={options} />
	}
}

HighchartsMore(Highcharts)

export class PolarSpiderChart extends Component {
	constructor(props, context) {
		super(props, context)
		this.chartComponent = React.createRef()
	}

	componentDidMount() {
		const container = this.chartComponent.current.container.current
		container.style.width = '100%'
		container.style.height = 'auto'
		this.chartComponent.current.chart.reflow()
	}

	render() {
		const { series, categories, hideLabel, labels } = this.props
		const options = {
			chart: {
				polar: true,
				type: 'areaspline'
			},

			title: {
				text: null
			},

			xAxis: {
				categories,
				tickmarkPlacement: 'on',
				lineWidth: 0,
				labels: {
					format:
						'<h6 style="font-size: 12px; letter-spacing: normal; color: #505d6f;">{value}</h6>'
				}
			},
			yAxis: {
				labels: {
					enabled: false
				}
			},
			tooltip: {
				enabled: true,
				formatter() {
					let count = 0
					series.map(pipeline => {
						if (pipeline.name === this.series.legendItem.textStr) {
							count = pipeline.count
						}
					})
					return (
						`<span>${this.series.legendItem.textStr}</span>` +
						`<br/>` +
						`<span><b>` +
						`Count: ${count}</b></span>` +
						`<br/>` +
						`<br/>` +
						`<span><b>`
					)
				}
			},
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 300
						},
						chartOptions: {
							pane: {
								size: '60%'
							}
						}
					}
				]
			},
			series,
			legend: {
				itemMarginTop: 5,
				itemMarginBottom: 5
			}
		}

		return (
			<div className={this.props.className}>
				<HighchartsReact
					ref={this.chartComponent}
					allowChartUpdate
					highcharts={Highcharts}
					options={options}
				/>
				{!hideLabel && (
					<div className="polar-label-pane">
						<Typography component="span" className="polar-label-wrapper">
							{labels.map((item, key) => (
								<Chip
									key={key}
									label={item.title}
									onDelete={() => {
										this.props.onDelete(item)
									}}
									variant="outlined"
									className="polar-label"
								/>
							))}
						</Typography>
					</div>
				)}
			</div>
		)
	}
}
PolarSpiderChart.propTypes = {
	series: PropTypes.array.isRequired,
	ref: PropTypes.any
}
PolarSpiderChart.defaultProps = {
	labels: []
}

export class AreaChart extends Component {
	constructor(props, context) {
		super(props, context)
		this.chartComponent = React.createRef()
	}

	componentDidMount() {
		const container = this.chartComponent.current.container.current
		container.style.width = '100%'
		this.chartComponent.current.chart.reflow()
	}

	clickHandle(e) {
		this.setState({ hoverData: e.target.category })
	}

	changeRange(range) {
		this.props.selectArea(range)
	}

	render() {
		// const { series } = this.props;

		const options = {
			chart: {
				type: 'area'
			},
			title: {
				text: ''
			},
			yAxis: {
				labels: {
					formatter() {
						return `${String(100 + this.value)}%`
					}
				},
				min: -100
			},
			xAxis: {
				categories: ['', '', '', '', '', '', '', '']
			},
			credits: {
				enabled: true
			},
			tooltip: {
				enabled: true,
				formatter() {
					return `${'<span>' + 'Talent Count: '}${Math.abs(this.y)}</span>`
				}
			},
			plotOptions: {
				area: {
					pointStart: 0,
					allowPointSelect: false,
					cursor: 'pointer',
					trackByArea: true,

					marker: {
						enabled: false,
						symbol: 'circle',
						radius: 2,
						states: {
							hover: {
								enabled: false
							}
						}
					}
				}
			},
			series: [
				{
					name: 'Top Performer',
					data: [0, -20, -80, -100, -100, -80, -20, 0],
					color: '#C8E6C9',
					events: {
						click: event => {
							this.changeRange(100)
						}
					}
				},
				{
					name: 'Medium Performer',
					data: [0, -20, -80, -80, -80, -80, -20, 0],
					color: '#FFF9C4',
					events: {
						click: event => {
							this.changeRange(80)
						}
					}
				},
				{
					name: 'Low Performer',
					data: [0, -20, -20, -20, -20, -20, -20, 0],
					color: '#ffcdd2',
					events: {
						click: event => {
							this.changeRange(20)
						}
					}
				}
			]
		}
		return (
			<div className={this.props.className}>
				<HighchartsReact
					ref={this.chartComponent}
					allowChartUpdate
					highcharts={Highcharts}
					options={options}
				/>
			</div>
		)
	}
}

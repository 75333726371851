import actions from './actions'

const initState = {
	categories: [],
	isLoading: false,
	errorMsg: null
}

export default function challengeReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.GET_CATEGORY_REQUEST:
			return {
				...state,
				isLoading: false,
				categories: null
			}

		case actions.GET_CATEGORY_SUCCESS:
			return {
				...state,
				isLoading: true,
				categories: payload
			}

		case actions.GET_CATEGORY_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload,
				categories: null
			}
		default:
			return state
	}
}

import adminReducer from './admin/reducer'
import authReducer from './auth/reducer'
import resultReducer from './result/reducer'
import sandboxReducer from './sandbox/reducer'
import categoryReducer from './category/reducer'
import chapterReducer from './chapter/reducer'

export default {
	adminReducer,
	authReducer,
	resultReducer,
	sandboxReducer,
	categoryReducer,
	chapterReducer
}

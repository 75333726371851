const actions = {
	REGISTER_SAND_REQUEST: 'REGISTER_SAND_REQUEST',
	REGISTER_SAND_SUCCESS: 'REGISTER_SAND_SUCCESS',
	REGISTER_SAND_FAILED: 'REGISTER_SAND_FAILED',

	LOGIN_SAND_REQUEST: 'LOGIN_SAND_REQUEST',
	LOGIN_SAND_SUCCESS: 'LOGIN_SAND_SUCCESS',
	LOGIN_SAND_FAILED: 'LOGIN_SAND_FAILED',

	LOGOUT_SAND_REQUEST: 'LOGOUT_SAND_REQUEST',
	LOGOUT_SAND_SUCCESS: 'LOGOUT_SAND_SUCCESS',
	LOGOUT_SAND_FAILED: 'LOGOUT_SAND_FAILED',

	OAUTH_SAND_TOKEN: 'OAUTH_SAND_TOKEN',

	GET_ALL_CHALLENGE_REQUEST: '@SANDBOX_GET_ALL_CHALLENGE_REQUEST',
	GET_ALL_CHALLENGE_SUCCESS: '@SANDBOX_GET_ALL_CHALLENGE_SUCCESS',
	GET_ALL_CHALLENGE_FAILED: '@SANDBOX_GET_ALL_CHALLENGE_FAILED',

	GET_CHALLENGE_REQUEST: '@SANDBOX_GET_CHALLENGE_REQUEST',
	GET_CHALLENGE_SUCCESS: '@SANDBOX_GET_CHALLENGE_SUCCESS',
	GET_CHALLENGE_FAILED: '@SANDBOX_GET_CHALLENGE_FAILED',

	SET_PROFILE_INFO_REQUEST: 'SET_PROFILE_INFO_REQUEST',
	SET_PROFILE_INFO_SUCCESS: 'SET_PROFILE_INFO_SUCCESS',
	SET_PROFILE_INFO_FAILED: 'SET_PROFILE_INFO_FAILED',

	UPDATE_PROFILE_INFO_REQUEST: 'UPDATE_PROFILE_INFO_REQUEST',
	UPDATE_PROFILE_INFO_SUCCESS: 'UPDATE_PROFILE_INFO_SUCCESS',
	UPDATE_PROFILE_INFO_FAILED: 'UPDATE_PROFILE_INFO_FAILED',

	UPDATE_SANDBOX_RECORD_REQUEST: 'UPDATE_SANDBOX_RECORD_REQUEST',
	UPDATE_SANDBOX_RECORD_SUCCESS: 'UPDATE_SANDBOX_RECORD_SUCCESS',
	UPDATE_SANDBOX_RECORD_FAILED: 'UPDATE_SANDBOX_RECORD_FAILED',

	UPDATE_SANDBOX_PPROFILE_REQUEST: 'UPDATE_SANDBOX_PPROFILE_REQUEST',
	UPDATE_SANDBOX_PPROFILE_SUCCESS: 'UPDATE_SANDBOX_PPROFILE_SUCCESS',
	UPDATE_SANDBOX_PPROFILE_FAILED: 'UPDATE_SANDBOX_PPROFILE_FAILED',

	GET_SANDDATA_REQUEST: 'GET_SANDDATA_REQUEST',
	GET_SANDDATA_SUCCESS: 'GET_SANDDATA_SUCCESS',
	GET_SANDDATA_FAILED: 'GET_SANDDATA_FAILED',

	GET_SANDDATA_PROFILE_REQUEST: 'GET_SANDDATA_PROFILE_REQUEST',
	GET_SANDDATA_PROFILE_SUCCESS: 'GET_SANDDATA_PROFILE_SUCCESS',
	GET_SANDDATA_PROFILE_FAILED: 'GET_SANDDATA_PROFILE_FAILED',

	GET_PROFILE_INFO_REQUEST: 'GET_PROFILE_INFO_REQUEST',
	GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
	GET_PROFILE_INFO_FAILED: 'GET_PROFILE_INFO_FAILED',

	GET_ECHAPTER_RESULT_REQUEST: 'GET_ECHAPTER_RESULT_REQUEST',
	GET_ECHAPTER_RESULT_SUCCESS: 'GET_ECHAPTER_RESULT_SUCCESS',
	GET_ECHAPTER_RESULT_FAILED: 'GET_ECHAPTER_RESULT_FAILED',

	SET_PD_QUESTIONNAIRE_REQUEST: 'SET_PD_QUESTIONNAIRE_REQUEST',
	SET_PD_QUESTIONNAIRE_SUCCESS: 'SET_PD_QUESTIONNAIRE_SUCCESS',
	SET_PD_QUESTIONNAIRE_FAILED: 'SET_PD_QUESTIONNAIRE_FAILED',

	SEND_VERIFY_REQUEST: 'SEND_VERIFY_REQUEST',
	SEND_VERIFY_REQUEST_SUCCESS: 'SEND_VERIFY_REQUEST_SUCCESS',
	SEND_VERIFY_REQUEST_FAILED: 'SEND_VERIFY_REQUEST_FAILED',

	CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
	CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',

	GET_USER_BY_USERNAME_REQUEST: 'GET_USER_BY_USERNAME_REQUEST',
	GET_USER_BY_USERNAME_SUCCESS: 'GET_USER_BY_USERNAME_FAILED',
	GET_USER_BY_USERNAME_FAILED: 'GET_USER_BY_USERNAME_SUCCESS',

	SET_BADGE_NOTIFICATION_REQUEST: 'SET_BADGE_NOTIFICATION_REQUEST',
	SET_BADGE_NOTIFICATION_SUCCESS: 'SET_BADGE_NOTIFICATION_SUCCESS',
	SET_BADGE_NOTIFICATION_FAILED: 'SET_BADGE_NOTIFICATION_FAILED',

	SET_ALL_BADGE_NOTIFICATION_REQUEST: 'SET_ALL_BADGE_NOTIFICATION_REQUEST',
	SET_ALL_BADGE_NOTIFICATION_SUCCESS: 'SET_ALL_BADGE_NOTIFICATION_SUCCESS',
	SET_ALL_BADGE_NOTIFICATION_FAILED: 'SET_ALL_BADGE_NOTIFICATION_FAILED',

	VALIDATE_SAND_USER: 'VALIDATE_SAND_USER',
	VALIDATE_SAND_USER_SUCCESS: 'VALIDATE_SAND_USER_SUCCESS',

	GET_SANDBOX_COINS_REQUEST: 'GET_SANDBOX_CONIS_REQUEST',
	GET_SANDBOX_COINS_SUCCESS: 'GET_SANDBOX_CONIS_SUCCESS',
	GET_SANDBOX_COINS_FAILED: 'GET_SANDBOX_CONIS_FAILED',

	SAVE_BADGE_VALUE_REQUEST: 'SAVE_BADGE_VALUE_REQUEST',
	SAVE_BADGE_VALUE_SUCCESS: 'SAVE_BADGE_VALUE_SUCCESS',
	SAVE_BADGE_VALUE_FAILED: 'SAVE_BADGE_VALUE_FAILED',

	GET_BADGE_VALUE_REQUEST: 'GET_BADGE_VALUE_REQUEST',
	GET_BADGE_VALUE_SUCCESS: 'GET_BADGE_VALUE_SUCCESS',
	GET_BADGE_VALUE_FAILED: 'GET_BADGE_VALUE_FAILED',
	GET_MARKET_CARDS_REQUEST: 'GET_MARKET_CARDS_REQUEST',
	GET_MARKET_CARDS_SUCCESS: 'GET_MARKET_CARDS_SUCCESS',
	GET_MARKET_CARDS_FAILED: 'GET_MARKET_CARDS_FAILED',
	BUY_MARKET_CARD_REQUEST: 'BUY_MARKET_CARD_REQUEST',
	BUY_MARKET_CARD_SUCCESS: 'BUY_MARKET_CARD_SUCCESS',
	BUY_MARKET_CARD_FAILED: 'BUY_MARKET_CARD_FAILED',
	UPDATE_COINS: 'UPDATE_COINS',

	PASSWORD_RESET_EMAIL_REQUEST: 'PASSWORD_RESET_EMAIL_REQUEST',
	PASSWORD_RESET_EMAIL_SUCCESS: 'PASSWORD_RESET_EMAIL_SUCCESS',
	PASSWORD_RESET_EMAIL_FAILED: 'PASSWORD_RESET_EMAIL_FAILED',

	ERASE_ERROR_MSG: 'ERASE_ERROR_MSG',
	getMarketCards: payload => ({
		type: actions.GET_MARKET_CARDS_REQUEST,
		payload
	}),
	getMarketCardsSuccess: payload => ({
		type: actions.GET_MARKET_CARDS_SUCCESS,
		payload
	}),
	buyMarketCard: payload => ({
		type: actions.BUY_MARKET_CARD_REQUEST,
		payload
	}),
	buyMarketCardSuccess: payload => ({
		type: actions.BUY_MARKET_CARD_SUCCESS,
		payload
	}),
	buyMarketCardFailed: payload => ({
		type: actions.BUY_MARKET_CARD_FAILED,
		payload
	}),
	setBadgeNotification: payload => ({
		type: actions.SET_BADGE_NOTIFICATION_REQUEST,
		payload
	}),
	setBadgeNotificationSuccess: payload => ({
		type: actions.SET_BADGE_NOTIFICATION_SUCCESS,
		payload
	}),
	setBadgeNotificationFailed: payload => ({
		type: actions.SET_BADGE_NOTIFICATION_FAILED,
		payload
	}),
	setAllBadgeNotification: () => ({
		type: actions.SET_ALL_BADGE_NOTIFICATION_REQUEST
	}),
	setAllBadgeNotificationSuccess: () => ({
		type: actions.SET_ALL_BADGE_NOTIFICATION_SUCCESS
	}),
	setAllBadgeNotificationFailed: () => ({
		type: actions.SET_ALL_BADGE_NOTIFICATION_FAILED
	}),
	registerTRequest: payload => ({
		type: actions.REGISTER_SAND_REQUEST,
		payload
	}),
	registerTSuccess: payload => ({
		type: actions.REGISTER_SAND_SUCCESS,
		payload
	}),
	registerTFailed: payload => ({
		type: actions.REGISTER_SAND_FAILED,
		payload
	}),

	loginTRequest: payload => ({ type: actions.LOGIN_SAND_REQUEST, payload }),
	loginTSuccess: payload => ({ type: actions.LOGIN_SAND_SUCCESS, payload }),
	loginTFailed: payload => ({ type: actions.LOGIN_SAND_FAILED, payload }),

	sandOAuthToken: payload => ({ type: actions.OAUTH_SAND_TOKEN, payload }),

	logoutTRequest: payload => ({ type: actions.LOGOUT_SAND_REQUEST, payload }),
	logoutTSuccess: payload => ({ type: actions.LOGOUT_SAND_SUCCESS, payload }),
	logoutTFailed: payload => ({ type: actions.LOGOUT_SAND_FAILED, payload }),

	getSandDataRequest: payload => ({
		type: actions.GET_SANDDATA_REQUEST,
		payload
	}),
	getSandDataSuccess: payload => ({
		type: actions.GET_SANDDATA_SUCCESS,
		payload
	}),
	getSandDataFailed: payload => ({
		type: actions.GET_SANDDATA_FAILED,
		payload
	}),

	getSandDataRequestByProfile: payload => ({
		type: actions.GET_SANDDATA_PROFILE_REQUEST,
		payload
	}),
	getSandDataRequestByProfileSuccess: payload => ({
		type: actions.GET_SANDDATA_PROFILE_SUCCESS,
		payload
	}),
	getSandDataRequestByProfileFailed: payload => ({
		type: actions.GET_SANDDATA_PROFILE_FAILED,
		payload
	}),
	getSandDataByUsername: payload => ({
		type: actions.GET_USER_BY_USERNAME_REQUEST,
		payload
	}),
	getSandDataByUsernameSucess: payload => ({
		type: actions.GET_USER_BY_USERNAME_SUCCESS,
		payload
	}),
	getSandDataByUsernameFailed: payload => ({
		type: actions.GET_USER_BY_USERNAME_FAILED,
		payload
	}),
	setSandProfileInfoRequest: payload => ({
		type: actions.SET_PROFILE_INFO_REQUEST,
		payload
	}),
	setSandProfileInfoSuccess: payload => ({
		type: actions.SET_PROFILE_INFO_SUCCESS,
		payload
	}),
	setSandProfileInfoFailed: payload => ({
		type: actions.SET_PROFILE_INFO_FAILED,
		payload
	}),

	getAllChallenge: payload => ({
		type: actions.GET_ALL_CHALLENGE_REQUEST,
		payload
	}),
	getAllChallengeSuccess: payload => ({
		type: actions.GET_ALL_CHALLENGE_SUCCESS,
		payload
	}),
	getAllChallengeFailed: payload => ({
		type: actions.GET_ALL_CHALLENGE_FAILED,
		payload
	}),

	getChallenge: payload => ({
		type: actions.GET_CHALLENGE_REQUEST,
		payload
	}),
	getChallengeSuccess: payload => ({
		type: actions.GET_CHALLENGE_SUCCESS,
		payload
	}),
	getChallengeFailed: payload => ({
		type: actions.GET_CHALLENGE_FAILED,
		payload
	}),

	getEChapterResult: payload => ({
		type: actions.GET_ECHAPTER_RESULT_REQUEST,
		payload
	}),
	getEChapterResultSuccess: payload => ({
		type: actions.GET_ECHAPTER_RESULT_SUCCESS,
		payload
	}),
	getEChapterResultFailed: payload => ({
		type: actions.GET_ECHAPTER_RESULT_FAILED,
		payload
	}),

	updateProfileDetails: payload => ({
		type: actions.UPDATE_PROFILE_INFO_REQUEST,
		payload
	}),
	updateProfileDetailsSuccess: payload => ({
		type: actions.UPDATE_PROFILE_INFO_SUCCESS,
		payload
	}),
	updateProfileDetailsFailed: payload => ({
		type: actions.UPDATE_PROFILE_INFO_FAILED,
		payload
	}),

	updateSandboxRecord: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_REQUEST,
		payload
	}),
	updateSandboxRecordSuccess: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_SUCCESS,
		payload
	}),
	updateSandboxRecordFailed: payload => ({
		type: actions.UPDATE_SANDBOX_RECORD_FAILED,
		payload
	}),

	updateSandPublicProfile: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_REQUEST,
		payload
	}),
	updateSandPublicProfileSuccess: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_SUCCESS,
		payload
	}),
	updateSandPublicProfileFailed: payload => ({
		type: actions.UPDATE_SANDBOX_PPROFILE_FAILED,
		payload
	}),

	getProfileDetails: payload => ({
		type: actions.GET_PROFILE_INFO_REQUEST,
		payload
	}),
	getProfileDetailsSuccess: payload => ({
		type: actions.GET_PROFILE_INFO_SUCCESS,
		payload
	}),
	getProfileDetailsFailed: payload => ({
		type: actions.GET_PROFILE_INFO_FAILED,
		payload
	}),

	setPDQuestionnaire: payload => ({
		type: actions.SET_PD_QUESTIONNAIRE_REQUEST,
		payload
	}),
	setPDQuestionnaireSuccess: payload => ({
		type: actions.SET_PD_QUESTIONNAIRE_SUCCESS,
		payload
	}),
	setPDQuestionnaireFailed: payload => ({
		type: actions.SET_PD_QUESTIONNAIRE_FAILED,
		payload
	}),

	sendVerifyRequest: payload => ({
		type: actions.SEND_VERIFY_REQUEST,
		payload
	}),
	sendVerifyRequestSuccess: payload => ({
		type: actions.SEND_VERIFY_REQUEST_SUCCESS,
		payload
	}),
	sendVerifyRequestFailed: payload => ({
		type: actions.SEND_VERIFY_REQUEST_FAILED,
		payload
	}),

	changePassword: payload => ({
		type: actions.CHANGE_PASSWORD_REQUEST,
		payload
	}),

	changePasswordSuccess: payload => ({
		type: actions.CHANGE_PASSWORD_SUCCESS,
		payload
	}),

	changePasswordFailed: payload => ({
		type: actions.CHANGE_PASSWORD_FAILED,
		payload
	}),

	eraseErrorMsg: () => ({
		type: actions.ERASE_ERROR_MSG
	}),

	getCoins: payload => ({
		type: actions.GET_SANDBOX_COINS_REQUEST,
		payload
	}),
	getCoinsSuccess: payload => ({
		type: actions.GET_SANDBOX_COINS_SUCCESS,
		payload
	}),
	getCoinsFailed: payload => ({
		type: actions.GET_SANDBOX_COINS_FAILED,
		payload
	}),

	saveBadgeValue: payload => ({
		type: actions.SAVE_BADGE_VALUE_REQUEST,
		payload
	}),
	saveBadgeValueSuccess: payload => ({
		type: actions.SAVE_BADGE_VALUE_SUCCESS,
		payload
	}),
	saveBadgeValueFailed: payload => ({
		type: actions.SAVE_BADGE_VALUE_FAILED,
		payload
	}),

	getBadgeValue: payload => ({
		type: actions.GET_BADGE_VALUE_REQUEST,
		payload
	}),
	getBadgeValueSuccess: payload => ({
		type: actions.GET_BADGE_VALUE_SUCCESS,
		payload
	}),
	getBadgeValueFailed: payload => ({
		type: actions.GET_BADGE_VALUE_FAILED,
		payload
	}),

	validateSandUser: payload => ({
		type: actions.VALIDATE_SAND_USER,
		payload
	}),
	validateSandUserSuccess: payload => ({
		type: actions.VALIDATE_SAND_USER_SUCCESS,
		payload
	}),

	sendPasswordReset: payload => ({
		type: actions.PASSWORD_RESET_EMAIL_REQUEST,
		payload
	}),
	sendPasswordResetSuccess: () => ({
		type: actions.PASSWORD_RESET_EMAIL_SUCCESS
	}),
	sendPasswordResetFailed: payload => ({
		type: actions.PASSWORD_RESET_EMAIL_FAILED,
		payload
	})
}

export default actions

import React from 'react'
import PropTypes from 'prop-types'
import './ChapterCard.scss'
import { Stepper, Step, StepLabel } from 'material-ui-next'
import styled from 'styled-components'

const StyledStep = styled(Step)({
	'& .MuiStepIcon-root': {
		backgroundColor: 'transparent !important',
		position: 'unset !important'
	},
	'& .MuiStepIcon-root.MuiStepIcon-completed': {
		color: props => props.mycolor
	}
})

class ChapterCard extends React.Component {
	handleOpenChapter = () => {
		const { selCategory, displaySelCategory } = this.props
		displaySelCategory(selCategory.category_id)
	}

	render() {
		const { activeStep, chapters, color, isPublic } = this.props

		return (
			<div>
				<Stepper
					activeStep={activeStep}
					orientation="vertical"
					className="stepper-container"
				>
					{(chapters || []).map((value, index) => (
						<StyledStep
							key={value._id}
							onClick={e => {
								if (!value.state && !isPublic) {
									this.handleOpenChapter()
								}
							}}
							className="step-item"
							mycolor={color}
						>
							<StepLabel
								className="step-title"
								active={false}
								StepIconProps={{
									classes: {
										root: 'icon-color',
										text: 'text-color'
									}
								}}
							>
								{value.name}
							</StepLabel>
						</StyledStep>
					))}
				</Stepper>
			</div>
		)
	}
}

ChapterCard.propTypes = {
	activeStep: PropTypes.number,
	chapters: PropTypes.array,
	color: PropTypes.string,
	displaySelCategory: PropTypes.func,
	isPublic: PropTypes.bool,
	selCategory: PropTypes.shape({
		category_id: PropTypes.string
	})
}

export default ChapterCard

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
	Avatar,
	Typography,
	Button,
	Grid,
	Snackbar,
	IconButton,
	CircularProgress
} from '@material-ui/core'
import moment from 'moment'

import SubjectIcon from '@material-ui/icons/Subject'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import CloseIcon from '@material-ui/icons/Close'
import './SandboxPublic.scss'

import ReactTooltip from 'react-tooltip'
import copy from 'copy-to-clipboard'
import CalendarHeatmap from 'react-calendar-heatmap'
import BadgeModal from '../../components/Modals/BadgeModal'
import { StackedBarChart } from '../Charts/Charts'
// import BadgeIcon from '../../images/icons/badge.svg'
import { ReactComponent as BadgeIcon } from '../../images/icons/badge.svg'
import HexagonIcon from '../../images/icons/hexagon.svg'
import NoDataIcon from '../../images/icons/no-data.svg'
import activeImg from '../../images/icons/active-icon.png'
import passiveImg from '../../images/icons/passive-icon.png'
import notlookImg from '../../images/icons/not-looking-icon.png'

import 'react-calendar-heatmap/dist/styles.css'
import axios from 'axios'
import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

import ChapterCard from '../SandboxProfile/ProfilePage/ChapterCard'
import { getAvatarPath } from '../../utils/userUtil'
import DefaultAvatar from '../../images/default-character.png'

import categoryActions from '../../redux/category/actions'
import sandboxActions from '../../redux/sandbox/actions'
import OverfitModal from '../../components/Modals/OverfitModal'
import { Info } from '@material-ui/icons'

const { getCategory } = categoryActions
const { updateSandboxRecord } = sandboxActions

const badge_list = [
	// hid for now
	// {
	// 	title: 'Savvy',
	// 	desc: 'This badge is awarded to users who get all the domain questions correct',
	// 	color: '#E2D08B',
	// 	gradient: 'linear-gradient(45deg, #848233, #E2D08B)'
	// },
	{
		title: 'Achiever',
		desc: 'This badge is awarded to users who do all of the challenges',
		color: '#B56B51',
		gradient: 'linear-gradient(45deg, #B76A4F, #A2796A)'
	},
	{
		title: 'Sprinter',
		desc: 'This badge is awarded to users who complete all the challenges in one day',
		color: '#2141C5',
		gradient: 'linear-gradient(45deg, #3A3D4C, #2141C5)'
	}
]

const month_names = [
	'January',
	'Febrary',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]

class ProfilePage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			viewSLLabel: 'See More',
			isShowMoreSubmission: false,
			activeStep: 0,
			skills: [],
			roles: [],
			newSkill: '',
			newRole: '',
			showBadgeModal: false,
			showOverfitModal: false,
			badgeData: {},
			categoryIndex: 0,
			snackMsg: '',
			openSnack: false,
			categories: null,
			skillValues: null,
			month_list: null,
			sCategories: null,
			sskills: null,
			schapters: null,
			loading: false,
			loading_skills: false
		}
	}

	async componentDidMount() {
		const { categories } = this.props
		this.setState({
			categories
		})
		if (this.props.data) {
			this.setState({
				skills: this.props.data.skills,
				roles: this.props.data.role_interests
			})
		}
		this.setState({
			loading: true
		})
	}

	async componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data && this.props.data) {
			this.setState({
				skills: this.props.data.skills,
				roles: this.props.data.role_interests
			})
		}

		if (this.props.registerPIResult !== prevProps.registerPIResult) {
			if (this.props.registerPIResult) {
				this.setState({
					openSnack: true,
					snackMsg: 'Successfully registered!'
				})
			}
		}

		if (
			(!prevProps.categories && this.props.categories && this.props.chapterResult) ||
			(!prevProps.chapterResult && this.props.chapterResult && this.props.categories)
		) {
			const { chapterResult, categories } = this.props
			const selCategories = []

			for (let i = 0; i < categories.length; i++) {
				const chapters = []
				for (let j = 0; j < categories[i].challenge_list.length; j++) {
					const challenge = categories[i].challenge_list[j]
					for (let k = 0; k < challenge.chapter_list.length; k++) {
						if (!chapters.some(e => e._id === challenge.chapter_list[k]._id)) {
							chapters.push(challenge.chapter_list[k])
						}
					}
				}
				if (chapters.length > 0) {
					selCategories.push({
						category: categories[i].name,
						color: categories[i].color,
						category_id: categories[i]._id,
						chapters
					})
				}
			}

			this.setState({
				selCategories
			})
			const { categoryIndex } = this.state
			await this.getEnabledCategories(chapterResult, selCategories, categoryIndex)

			// const month_list = []
			// for (let i = 0; i < 12; i++) {
			// 	const start_date = new Date(new Date().getFullYear(), i, 1)
			// 	const end_date = new Date(new Date().getFullYear(), i + 1, 0)
			// 	const content = []

			// 	for (let k = 1; k <= 31; k++) {
			// 		// let bDate = new Date(start_date.getFullYear() + '-' + ('0' + (i + 1)).slice(-2) + '-' + ('0' + k).slice(-2) + 'T00:00:00Z');
			// 		const bDate = this.parseDate(`${i + 1}/${k}/${start_date.getFullYear()}`)
			// 		const bb = chapterResult.filter(
			// 			e =>
			// 				moment(e.createdAt).format('YYYY-MM-DD') ===
			// 				moment(bDate).format('YYYY-MM-DD')
			// 		).length
			// 		if (bb > 0) {
			// 			content.push({
			// 				date: moment(bDate).format('YYYY-MM-DD'),
			// 				count: bb
			// 			})
			// 		}
			// 	}

			// 	month_list.push({
			// 		month_name: month_names[i],
			// 		start: [
			// 			start_date.getFullYear(),
			// 			`0${start_date.getMonth() + 1}`.slice(-2),
			// 			`0${start_date.getDate()}`.slice(-2)
			// 		].join('-'),
			// 		end: [
			// 			end_date.getFullYear(),
			// 			`0${end_date.getMonth() + 1}`.slice(-2),
			// 			`0${end_date.getDate()}`.slice(-2)
			// 		].join('-'),
			// 		content
			// 	})
			// }

			this.setState({
				// month_list,
				loading: false
			})
		}
	}

	parseDate(s) {
		const b = s.split(/\D+/)
		return new Date(b[2], --b[0], b[1])
	}

	changeViewSubmissions = () => {
		if (!this.state.isShowMoreSubmission) {
			this.setState({
				viewSLLabel: 'See Less',
				isShowMoreSubmission: true
			})
		} else {
			this.setState({
				viewSLLabel: 'See More',
				isShowMoreSubmission: false
			})
		}
	}

	handleViewDomain(i) {}

	handleNext = () => {
		this.setState({
			activeStep: this.state.activeStep + 1
		})
	}

	handleBack = () => {
		this.setState({
			activeStep: this.state.activeStep - 1
		})
	}

	handleViewStep = i => {
		this.setState({
			activeStep: i
		})
	}

	handleReset = () => {}

	handleSelectRole = role => {
		const { userId } = this.props.match.params
		const { updateSandboxRecord } = this.props
		const { roles } = this.state

		roles.push(role.fullname)
		updateSandboxRecord({
			roles,
			type: 'role',
			userId
		})
		this.setState({
			roles
		})
	}

	handleRemoveRole = role => {
		const { userId } = this.props.match.params
		const { updateSandboxRecord } = this.props
		const { roles } = this.state

		updateSandboxRecord({
			roles: roles.filter(e => {
				if (e !== role) {
					return e
				}
			}),
			type: 'role',
			userId
		})
		this.setState({
			roles: roles.filter(e => {
				if (e !== role) {
					return e
				}
			})
		})
	}

	handleSelectSkill = skill => {
		const { userId } = this.props.match.params
		const { updateSandboxRecord } = this.props
		const { skills } = this.state

		let skill_array = skills
		if (!skill_array) {
			skill_array = []
		}

		skill_array.push(skill.fullname)
		updateSandboxRecord({
			skills: skill_array,
			type: 'skill',
			userId
		})
		this.setState({
			skills: skill_array
		})
	}

	handleRemoveSkill = skill => {
		const { userId } = this.props.match.params
		const { updateSandboxRecord } = this.props
		const { skills } = this.state

		const res = skills.filter(e => e !== skill)
		updateSandboxRecord({
			skills: res,
			type: 'skill',
			userId
		})
		this.setState({
			skills: res
		})
	}

	handleKeyDown = event => {}

	handleShowBadge = value => {
		this.setState({
			showBadgeModal: true,
			badgeData: value
		})
	}

	handleCloseBadge = () => {
		this.setState({
			showBadgeModal: false
		})
	}

	handleShowFit = () => {
		this.setState({
			showOverfitModal: true
		})
	}

	handleCloseFit = () => {
		this.setState({
			showOverfitModal: false
		})
	}

	handleChangeCategory = async index => {
		this.setState({
			categoryIndex: index,
			loading_skills: true
		})

		const { currentSandId } = this.props
		const { sCategories } = this.state

		this.getReorderedChapters(sCategories, index)

		const params = {
			url: getEndpoint(`getSValue/${sCategories[index].category_id}/${currentSandId}`),
			method: 'post',
			headers: getHeaders(),
			data: {
				releveance: null
			}
		}

		await axios(params)
			.then(res => {
				const skillValues = res.data.data
				this.setState({
					skillValues
				})
				// if (skillValues.content.length > 0) {
				this.getChapterSkills(sCategories, skillValues.content, skillValues.dContent)
				// }
			})
			.catch(() => {
				this.setState({
					sskills: null,
					loading_skills: false
				})
			})
	}

	handleCloseSnack = () => {
		this.setState({ openSnack: false })
	}

	getChapterSkills = (categories, sValue, dValue) => {
		const { chapterResult } = this.props
		const { categoryIndex } = this.state
		const selCategory = categories[categoryIndex]
		const { color } = selCategory
		const p_skill_categories = []
		const o_skill_categories = []
		const p_skill_cate_series = []
		const o_skill_cate_series = []
		const p_skill_user_series = []
		const o_skill_user_series = []
		const pskills = []
		const dskills = []
		let ccskills = null

		if (selCategory) {
			if (chapterResult) {
				for (let i = 0; i < chapterResult.length; i++) {
					for (let k = 0; k < selCategory.chapters.length; k++) {
						if (selCategory.chapters[k]._id === chapterResult[i].chapter_id._id) {
							const { skills } = selCategory.chapters[k]
							for (let l = 0; l < skills.length; l++) {
								if (skills[l].type === 'People') {
									if (!pskills.some(e => e._id === skills[l]._id)) {
										const [ski] = skills[l].category_list.filter(e => {
											if (e.category_id === selCategory.category_id) {
												return e
											}
										})
										skills[l].slevel = ski && ski.skill_level ? ski.skill_level : 0
										pskills.push(skills[l])
									}
								} else if (skills[l].type === 'Domain') {
									if (!dskills.some(e => e._id === skills[l]._id)) {
										const [ski] = skills[l].category_list.filter(e => {
											if (e.category_id === selCategory.category_id) {
												return e
											}
										})
										skills[l].slevel = ski && ski.skill_level ? ski.skill_level : 0
										dskills.push(skills[l])
									}
								}
							}
						}
					}
				}
			}

			pskills.map(skill => {
				// p_skill_categories.push(skill.name);
				// p_skill_cate_series.push(parseInt(skill.slevel));
				if (sValue && sValue.length > 0) {
					if (sValue.some(e => e.name === skill.name)) {
						p_skill_categories.push(skill.name)
						p_skill_cate_series.push(parseInt(skill.slevel))
						for (let i = 0; i < sValue.length; i++) {
							if (sValue[i].name === skill.name) {
								p_skill_user_series.push(Math.ceil(sValue[i].p_score))
							}
						}
					}
				}
			})

			dskills.map(skill => {
				if (dValue && dValue.length > 0) {
					if (dValue.some(e => e.name === skill.name)) {
						o_skill_categories.push(skill.name)
						o_skill_cate_series.push(parseInt(skill.slevel))
						for (let i = 0; i < dValue.length; i++) {
							if (dValue[i].name === skill.name) {
								o_skill_user_series.push(Math.ceil(dValue[i].d_score))
							}
						}
					}
				}
			})

			const people_skills = {
				categories: p_skill_categories,
				series: [
					{
						name: 'You',
						data: p_skill_user_series
					},
					{
						name: selCategory.category,
						data: p_skill_cate_series
					}
				],
				colors: ['#bf7b0f', color || '#7773a6']
			}
			const domain_skills = {
				categories: o_skill_categories,
				series: [
					{
						name: 'You',
						data: o_skill_user_series
					},
					{
						name: selCategory.category,
						data: o_skill_cate_series
					}
				],
				colors: ['#bf7b0f', color || '#7773a6']
			}

			if (o_skill_categories.length === 0 && p_skill_categories.length === 0) {
				ccskills = null
			} else {
				ccskills = { domain: domain_skills, people: people_skills }
			}
		}

		this.setState({
			sskills: ccskills,
			loading_skills: false
		})
	}

	changeDateFormat = date => {
		const d = new Date(date)
		return d.toDateString('default', { month: 'short' })
	}

	getEnabledCategories = async (chapterResult, selCategories, index) => {
		const { currentSandId } = this.props

		const newCategories = []
		for (let i = 0; i < chapterResult.length; i++) {
			for (let j = 0; j < selCategories.length; j++) {
				const selCategory = selCategories[j]
				for (let k = 0; k < selCategory.chapters.length; k++) {
					if (selCategory.chapters[k]._id === chapterResult[i].chapter_id._id) {
						if (!newCategories.some(e => e.category_id === selCategory.category_id)) {
							newCategories.push(selCategory)
						}
					}
				}
			}
		}
		this.getReorderedChapters(newCategories, index)
		// this.getChapterSkills(newCategories, null);
		if (newCategories && newCategories.length > 0) {
			const params = {
				url: getEndpoint(`getSValue/${newCategories[index].category_id}/${currentSandId}`),
				method: 'post',
				headers: getHeaders(),
				data: {
					releveance: null
				}
			}

			await axios(params)
				.then(res => {
					const skillValues = res.data.data
					this.setState({
						skillValues
					})
					// if (skillValues.content.length > 0) {
					this.getChapterSkills(newCategories, skillValues.content, skillValues.dContent)
					// }
				})
				.catch(() => {
					this.setState({
						sskills: null,
						loading_skills: false
					})
				})
			this.setState({
				sCategories: newCategories
			})
		}
		return newCategories
	}

	getReorderedChapters = (categories, index) => {
		const { chapterResult } = this.props
		const selCategory = categories[index]
		let newChapters = []

		if (selCategory) {
			let aChapters = []
			let bChapters = []
			for (let i = 0; i < chapterResult.length; i++) {
				for (let k = 0; k < selCategory.chapters.length; k++) {
					if (selCategory.chapters[k]._id === chapterResult[i].chapter_id._id) {
						if (!aChapters.some(e => e._id === selCategory.chapters[k]._id)) {
							const cDate = new Date(chapterResult[i].createdAt)
							selCategory.chapters[k].date = cDate.getTime()
							selCategory.chapters[k].active = true
							aChapters.push(selCategory.chapters[k])
						} else {
							for (let j = 0; j < aChapters.length; j++) {
								if (selCategory.chapters[k]._id === aChapters[j]._id) {
									const cDate = new Date(chapterResult[i].createdAt)
									aChapters[j].date = cDate.getTime()
									aChapters[j].active = true
								}
							}
						}
					}
				}
			}

			for (let i = 0; i < selCategory.chapters.length; i++) {
				if (!aChapters.some(e => e._id === selCategory.chapters[i]._id)) {
					const cDate = new Date(selCategory.chapters[i].createdAt)
					selCategory.chapters[i].date = cDate.getTime()
					selCategory.chapters[i].active = false
					bChapters.push(selCategory.chapters[i])
				}
			}
			aChapters = aChapters.length > 0 && this.chapterSort(aChapters)
			newChapters = newChapters.concat(aChapters)
			if (bChapters.length > 0) {
				bChapters = aChapters.length > 0 && this.chapterSort(bChapters)
				newChapters = newChapters.concat(bChapters)
			}
		}

		this.setState({
			schapters: newChapters
		})
	}

	chapterSort = chapters => {
		return chapters.sort((a, b) => {
			if (a.date < b.date) {
				return 1
			}
			if (a.date > b.date) {
				return -1
			}
			return 0
		})
	}

	handleCopy = url => {
		copy(url)
		this.setState({
			openSnack: true,
			snackMsg: 'success-copied'
		})
	}

	render() {
		const { data, chapterResult, selCategories, categories } = this.props
		const {
			roles,
			skills,
			categoryIndex,
			openSnack,
			snackMsg,
			sCategories,
			// month_list,
			sskills,
			schapters,
			skillValues,
			loading,
			loading_skills
		} = this.state
		const general_infos = []
		if (data) {
			if (data.role_function !== '') {
				general_infos.push(data.role_function)
			}
			if (data.role_level !== '') {
				general_infos.push(data.role_level)
			}
			// if (data.edu_level !== '') {
			//   general_infos.push(data.edu_level);
			// }
			// if (data.prof_year !== '') {
			//   general_infos.push(data.prof_year);
			// }

			if (data.jobTypes) {
				for (let i = 0; i < data.jobTypes.length; i++) {
					general_infos.push(data.jobTypes[i])
				}
			}
			if (data.certifications) {
				for (let i = 0; i < data.certifications.length; i++) {
					general_infos.push(data.certifications[i])
				}
			}
		}

		let category_color = {}
		let category_bgColor = {}
		if (sCategories && sCategories.length > 0) {
			category_color = {
				color: sCategories[categoryIndex].color
			}
			category_bgColor = {
				backgroundColor: sCategories[categoryIndex].color
			}
		}

		let activeStep = 0

		activeStep = schapters && schapters.filter(e => e.active === true).length

		const completedChapters = []
		let service_list = []

		if (sCategories && chapterResult) {
			for (let i = 0; i < chapterResult.length; i++) {
				const aChapters = sCategories[categoryIndex].chapters

				for (let j = 0; j < aChapters.length; j++) {
					if (chapterResult[i].chapter_id._id === aChapters[j]._id) {
						if (!completedChapters.some(e => e === aChapters[j]._id)) {
							completedChapters.push(aChapters[j]._id)
						}
					}
				}
			}

			if (this.state.isShowMoreSubmission === true) {
				service_list = chapterResult.sort((a, b) => {
					if (a.createdAt < b.createdAt) {
						return 1
					}
					if (a.createdAt > b.createdAt) {
						return -1
					}
					return 0
				})
			} else {
				service_list = chapterResult
					.sort((a, b) => {
						if (a.createdAt < b.createdAt) {
							return 1
						}
						if (a.createdAt > b.createdAt) {
							return -1
						}
						return 0
					})
					.slice(0, 3)
			}
		}

		const sValue =
			skillValues && skillValues.o_f_score ? Math.floor(skillValues.o_f_score) : 0

		let fit_value = ''
		if (sValue >= 0 && sValue <= 50) {
			fit_value = 'complement-fit'
		} else if (sValue > 50 && sValue <= 75) {
			fit_value = 'close-fit'
		} else if (sValue > 75 && sValue < 100) {
			fit_value = 'target-fit'
		} else if (sValue >= 100) {
			fit_value = 'target-fit'
		}
		const notCompletedAll = skillValues?.o_f_score === null

		let badges = []
		if (data) {
			const earned = data.badges.filter(({ isEarned }) => isEarned === true)
			if (earned.length > 0) {
				earned.forEach(badge => {
					const exists = badges.findIndex(val => val.name === badge.badge_name)
					const index = badge_list.findIndex(val => val.title === badge.badge_name)
					const { title, ...rest } = badge_list[index]
					if (exists === -1) {
						badges.push({
							name: badge.badge_name,
							isEarned: true,
							categories: [{ ...badge.category_id, updatedAt: badge.updatedAt }],
							...rest
						})
					} else {
						badges[exists].categories.push(badge.category_id)
					}
				})
			}
			badges = [
				...badges,
				...badge_list
					.filter(({ title }) => !badges.some(({ name }) => name === title))
					.map(val => ({
						...val,
						name: val.title,
						isEarned: false
					}))
			]
		}

		return !loading ? (
			<div className="profile-part">
				<div className="left-profile-part">
					<div className="personal-common-info">
						<div className="avatar-container">
							<div className="avatar-part">
								<Avatar
									className="personal-com-avatar"
									src={getAvatarPath(data && data.profile_image) || DefaultAvatar}
								/>
								{data && data.status === 'Actively looking' && (
									<img src={activeImg} className="status-img" alt="" />
								)}
								{data && data.status === 'Passively looking' && (
									<img src={passiveImg} className="status-img" alt="" />
								)}
								{data && data.status === 'Not looking' && (
									<img src={notlookImg} className="status-img" alt="" />
								)}
							</div>
						</div>

						<Typography className="personal-fullname" variant="subtitle1" component="label">
							{data ? `${data.first_name} ${data.last_name}` : ''}
						</Typography>

						<Typography className="personal-position" variant="subtitle1" component="label">
							{data ? data.location : ''}
						</Typography>

						{/* {data && data.isSalaryPublic && data.salaryRange && data.salaryRange[0] > 0 && (
							<Typography
								className="personal-position"
								variant="subtitle1"
								component="label"
							>
								Salary:{' '}
								{data.salaryRange
									? data.salaryRange.map(e => (e > 0 ? `$${e}K` : 0)).join(' - ')
									: ''}
							</Typography>
						)} */}

						<div className="personal-social">
							{data && data.linkedin_link && (
								<LinkedInIcon
									className="social-linkedin"
									onClick={e => {
										window.open(data.linkedin_link)
									}}
								/>
							)}

							{data && data.resume_link && (
								<SubjectIcon
									className="resume-link"
									onClick={e => {
										window.open(data.resume_link)
									}}
								/>
							)}
						</div>
					</div>
					{data && general_infos && general_infos.filter(e => e !== null).length > 0 && (
						<div className="personal-general-info">
							<Typography
								className="personal-general-label"
								variant="subtitle1"
								component="label"
							>
								{this.context.t('general')}
							</Typography>

							<div className="personal-general-buttons">
								{data &&
									general_infos.map((value, index) => {
										return (
											<Button variant="contained" key={value}>
												{value}
											</Button>
										)
									})}
							</div>
						</div>
					)}
					{skills && skills.length > 0 && (
						<div className="personal-skills-info">
							<Typography
								className="personal-skills-label"
								variant="subtitle1"
								component="label"
							>
								{this.context.t('skills')}
							</Typography>

							<div className="personal-skills-buttons">
								{skills.map((value, index) => {
									return <Button key={value}>{value}</Button>
								})}

								{/* <Button className="button-add-new">
									{this.context.t('add-new')}
								</Button> */}
							</div>
						</div>
					)}
					{roles && roles.length > 0 && (
						<div className="personal-roles-info">
							<Typography
								className="personal-roles-label"
								variant="subtitle1"
								component="label"
							>
								{this.context.t('roles')}
							</Typography>

							<div className="personal-roles-buttons">
								{roles.map((value, index) => {
									return <Button key={value}>{value}</Button>
								})}

								{/* <Button className="button-add-new">
                {this.context.t('add-new')}
              </Button> */}
							</div>
						</div>
					)}
				</div>

				<div className="right-profile-part">
					<div className="click-comment">
						<Typography className="comment" variant="subtitle1" component="label">
							{this.context.t('click-domain')}
						</Typography>
					</div>

					{sCategories && (
						<Grid className="top-part-container" container spacing={8}>
							<Grid style={{ paddingBottom: 24 }} item xs={12} sm={12} md={5} lg={3}>
								<div className="top-rate-part">
									<div className="top-rate-item-p">
										<div className="left-category-part">
											<div className="content">
												{sCategories &&
													sCategories.map((val, ind) => {
														return (
															<div
																className="content-item"
																style={{
																	backgroundColor: val.color,
																	border:
																		ind === categoryIndex
																			? '3px solid rgba(255, 255, 255, 0.65)'
																			: '3px solid white'
																}}
																key={ind}
																onClick={e => {
																	this.setState({
																		categoryIndex: ind
																	})
																	this.handleChangeCategory(ind)
																}}
															/>
														)
													})}
											</div>
										</div>
										<div className="main-rate-part">
											<div className="title">
												{this.context.t('overall-fit')}
												<Info onClick={this.handleShowFit} />
											</div>
											<div className="rate">
												{notCompletedAll ? (
													<button className="rate-btn" style={{ color: 'black' }}>
														Collecting Data...
													</button>
												) : (
													<button className="rate-btn" style={category_bgColor}>
														{this.context.t(fit_value)}
													</button>
												)}
											</div>
											<div className="comment">
												{sCategories &&
													sCategories.length > 0 &&
													sCategories[categoryIndex].category}
											</div>
										</div>
										{/* <div className="main-rate-part">
											<div className="title">
												{this.context.t('overall-fit')}
												<Info onClick={this.handleShowFit} />
											</div>
											<div className="rate">
												<button className="rate-btn" style={{ backgroundColor: '#323232' }}>
													Not available
												</button>
											</div>
											<div className="comment">
												{sCategories &&
													sCategories.length > 0 &&
													sCategories[categoryIndex].category}
											</div>
										</div> */}
									</div>

									{/* <div className="top-rate-item">
                    <div className="main-rate-part">
                      <div className="title">Score strength</div>
                      <div className="other-content" style={category_color}>
                        {parseInt(Math.floor(Math.random() * 16) + 81)}%
                      </div>
                    </div>
                  </div>

                  <div className="top-rate-item">
                    <div className="main-rate-part">
                      <div className="title">Chapters</div>
                      <div className="other-content" style={category_color}>
                        {completedChapters.length}
                      </div>
                    </div>
                  </div> */}
								</div>
							</Grid>
						</Grid>
					)}

					{sskills && (
						<Grid className="skill-chapter-part" container spacing={8}>
							<Grid item xs={12} sm={12} md={8} lg={8}>
								<div className="skills-part">
									<Typography className="title" variant="subtitle1" component="label">
										{this.context.t('skills')}
									</Typography>
									{!loading_skills ? (
										<div className="chart-container">
											{sskills?.people?.categories?.length > 0 && (
												<div className="skills-container">
													<div className="skills-title">
														{this.context.t('people-skills')}
													</div>
													<div className="skills-content">
														<StackedBarChart
															className="chart-part"
															chartdata={sskills && sskills.people}
														/>
													</div>
												</div>
											)}
											{sskills?.domain?.categories?.length > 0 && (
												<div className="skills-container">
													<div className="skills-title">
														{this.context.t('domain-skills')}
													</div>
													<div className="skills-content">
														<StackedBarChart
															className="chart-part"
															chartdata={sskills && sskills.domain}
														/>
													</div>
												</div>
											)}
										</div>
									) : (
										<div className="blank-container">
											<CircularProgress className="blank-loading-icon" />
										</div>
									)}
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4}>
								<div className="chapters-part">
									<div className="title-part">
										<Typography className="title" variant="subtitle1" component="label">
											Challenges
										</Typography>
									</div>

									<ChapterCard
										activeStep={activeStep}
										chapters={schapters}
										color={category_color.color}
										isPublic
									/>
								</div>
							</Grid>
						</Grid>
					)}

					{!sskills && (
						<Grid
							container
							className={chapterResult?.length === 0 ? '' : 'skill-chapter-part'}
							spacing={chapterResult?.length === 0 ? 0 : 8}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={chapterResult?.length === 0 ? 12 : 8}
								lg={chapterResult?.length === 0 ? 12 : 8}
							>
								<div
									className="skills-none-part"
									style={chapterResult?.length > 0 ? { marginTop: 0 } : {}}
								>
									<div className="skills-main-container">
										<div className="skills-title">{this.context.t('skills')}</div>
										<div className="skills-no-data">
											<img className="skills-no-data-img" src={NoDataIcon} alt="" />
											<div className="no-data-comment">
												<p>{this.context.t('enough-data')}</p>
												{chapterResult?.length === 0 ? (
													<p>
														<span
															className="underline-comment"
															onClick={this.props.displayStory}
														>
															{this.context.t('complete-challenge')}
														</span>
														{this.context.t('to-see-your-fit')}
													</p>
												) : (
													<p>The results for the challenge are on the way...</p>
												)}
											</div>
										</div>
									</div>
								</div>
							</Grid>
							{chapterResult?.length > 0 && (
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<div className="chapters-part">
										<div className="title-part">
											<Typography className="title" variant="subtitle1" component="label">
												Challenges
											</Typography>
										</div>

										<ChapterCard
											activeStep={activeStep}
											chapters={schapters}
											// selCategory={sCategories && sCategories[categoryIndex]}
											color={category_color.color}
											isPublic
											// displaySelCategory={this.props.displaySelCategory}
										/>
									</div>
								</Grid>
							)}
						</Grid>
					)}

					<div className="badge-part">
						<div className="badge-title">{this.context.t('badges')}</div>
						<div className="badge-content">
							<div className="badge-content-list">
								<Grid container spacing={8} className="content-list-container">
									{badges &&
										badges.map((val, i) => {
											return (
												<Grid
													item
													xs={6}
													sm={4}
													md={3}
													lg={2}
													key={i}
													onClick={() => {
														this.handleShowBadge(val)
													}}
												>
													<div className="badge-content-item">
														<div className="top-part">
															{val.categories &&
																val.categories.map(e => {
																	return (
																		<div
																			key={e._id}
																			className="top-part-item"
																			style={{ background: e.color }}
																		/>
																	)
																})}
														</div>
														<div
															className={`badge-item-icon ${val.isEarned && 'earned'}`}
															style={{
																background: val.isEarned ? val.gradient : '#DFE1E6'
															}}
														>
															<BadgeIcon fill={val.isEarned ? '#fff' : '#9FA9BA'} />
														</div>
														<div
															style={{
																color: val.isEarned ? '#000' : 'inherit'
															}}
														>
															{val.name}
														</div>
													</div>
												</Grid>
											)
										})}
								</Grid>
							</div>
						</div>
					</div>

					{/* <div className="submissions-container">
						<div className="main-container">
							<div className="title">{this.context.t('submission')}</div>

							<div className="year-title">2020</div>

							<div className="year-calendar-container">
								<Grid container spacing={8}>
									{month_list &&
										month_list.map((res, index) => {
											return (
												<Grid
													item
													xs={6}
													sm={4}
													md={3}
													lg={2}
													key={index}
													className="year-calendar-child"
												>
													<div className="year-calendar-item">
														<div className="item-month-part">
															<CalendarHeatmap
																className="calendar-heat-map-part"
																startDate={res.start}
																endDate={res.end}
																horizontal={false}
																showMonthLabels={false}
																tooltipDataAttrs={value => {
																	return {
																		'data-tip': `Chapters: ${
																			value.count ? value.count : '0'
																		}`
																	}
																}}
																gutterSize={5}
																values={res.content}
																classForValue={value => {
																	if (!value) {
																		return 'color-empty'
																	}
																	if (value.count === 1) {
																		return 'color-scale-1'
																	}
																	if (value.count === 2) {
																		return 'color-scale-2'
																	}
																	if (value.count >= 3) {
																		return 'color-scale-3'
																	}
																}}
															/>
															<ReactTooltip />
														</div>
														<div className="item-month-name">{res.month_name}</div>
													</div>
												</Grid>
											)
										})}
								</Grid>
							</div>

							<div className="service-rate">
								<div className="rate-1" />
								<div className="rate-2" />
								<div className="rate-3" />
							</div>

							<div className="service-list">
								<div className="service-list-title">
									{chapterResult && chapterResult.length > 1 && (
										<>Completed {chapterResult.length} chapters</>
									)}
									{chapterResult && chapterResult.length === 1 && (
										<>Completed {chapterResult.length} chapter</>
									)}
								</div>
								{service_list &&
									service_list.map((val, index) => {
										return (
											<div className="service-item" key={index}>
												<div className="title">{val.chapter_id.name}</div>
												<div className="date">{this.changeDateFormat(val.createdAt)}</div>
											</div>
										)
									})}
								{chapterResult && chapterResult.length > 0 && (
									<div className="service-more" onClick={this.changeViewSubmissions}>
										{chapterResult && chapterResult.length > 2 && this.state.viewSLLabel}
									</div>
								)}
							</div>
						</div>
					</div> */}
				</div>

				<BadgeModal
					show={this.state.showBadgeModal}
					handleClose={this.handleCloseBadge}
					badge={this.state.badgeData}
				/>

				<OverfitModal
					show={this.state.showOverfitModal}
					onHide={this.handleCloseFit}
					title={fit_value}
				/>

				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					open={openSnack}
					autoHideDuration={3000}
					onClose={this.handleCloseSnack}
					ContentProps={{
						'aria-describedby': 'message-id'
					}}
					message={<span id="message-id">{this.context.t(snackMsg)}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={this.handleCloseSnack}
						>
							<CloseIcon />
						</IconButton>
					]}
				/>
			</div>
		) : (
			<div>
				<CircularProgress className="centered" />
			</div>
		)
	}
}

ProfilePage.contextTypes = {
	t: PropTypes.func
}

ProfilePage.propTypes = {
	updateSandboxRecord: PropTypes.func,
	getCategory: PropTypes.func
}

const mapStateToProps = state => ({
	registerPIResult: state.sandboxReducer.registerPIResult,
	categories: state.categoryReducer.categories,
	sChapterResult: state.sandboxReducer.sChapterResult
})

const mapDispatchToProps = {
	updateSandboxRecord,
	getCategory
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage))

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { env } from './constants/config'
import App from './containers/App/App'

Sentry.init({
	dsn: 'https://b044d0edc93b4e0891fc5d94538ba49e@o427421.ingest.sentry.io/5374257',
	environment: env,
	enabled: process.env.NODE_ENV === 'production'
})

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
)

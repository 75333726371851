import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import cookie from 'react-cookies'
import { Button, AppBar, Toolbar, IconButton } from '@material-ui/core'

import axios from 'axios'
import topLogo from '../../images/logo/nugget-icon-white.svg'
import './PublicHeader.scss'

// import CirclegroupImage from '../../images/icons/circle-groups.png';
import TopLeftImage from '../../images/icons/top-left-rectangle.png'

import sandboxActions from '../../redux/sandbox/actions'
import categoryActions from '../../redux/category/actions'
import SandboxPublic from './SandboxPublic'
import { getToken } from '../../utils/localStorageUtil'
import AddToPipeline from '../../components/Modals/AddToPipeline'
import { getEndpoint } from '../../utils/urlHelper'

const { getCategory } = categoryActions
const { getSandDataRequestByProfile, getEChapterResult } = sandboxActions

// const [anchorEl, setAnchorEl] = React.useState(null);

// const isOpenPopover = Boolean(anchorEl);
// const popoverId = isOpenPopover ? 'logout-popover' : undefined;

class SandboxProfile extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			tabValue: 1,
			pop_open: false,
			sandboxToken: null,
			analyticsToken: null,
			analyticsUser: null,
			showAddToPP: false
		}
	}

	componentDidMount() {
		const { getSandDataRequestByProfile, getCategory } = this.props
		const { publicProfile, username } = this.props.match.params
		// let pp = `${window.location.origin}/sb/profile/` + publicProfile;
		const sandboxToken = getToken()
		const analyticsToken = cookie.load('analytics_token')
		this.setState({
			sandboxToken
		})
		if (analyticsToken) {
			axios
				.get(getEndpoint('auth/user'), {
					headers: { Authorization: analyticsToken }
				})
				.then(({ data }) => {
					if (data?.user) {
						this.setState({
							analyticsUser: data.user,
							analyticsToken
						})
					}
				})
				.catch(() => {})
		}
		getSandDataRequestByProfile(username)

		getCategory()
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.sandProfileData &&
			prevProps.sandProfileData !== this.props.sandProfileData
		) {
			const { sandProfileData, getEChapterResult } = this.props

			getEChapterResult(sandProfileData.uuid)
		}
	}

	handleProfileDropDown = e => {
		e.preventDefault()
		this.setState(prevState => ({
			pop_open: !prevState.pop_open
		}))
	}

	handleRequestClose = () => {
		this.setState({
			pop_open: false
		})
	}

	handleShowPP = () => {
		this.setState(prevState => ({
			showAddToPP: !prevState.showAddToPP
		}))
	}

	moveSignupPage = () => {
		const { sandboxToken } = this.state
		if (sandboxToken) {
			this.props.history.push('/user/')
		} else {
			this.props.history.push('/signup-talent')
		}
	}

	render() {
		const { sandProfileData, sChapterResult, errorMsg } = this.props

		const { tabValue, analyticsToken, showAddToPP, analyticsUser } = this.state

		let uuid = ''
		let sand_data = null

		if (sandProfileData) {
			sand_data = sandProfileData
			uuid = sandProfileData.uuid
		}

		const user = {
			first_name: sand_data ? sand_data.first_name : '',
			isVerified: true,
			img: sand_data && sand_data.profile_image
		}

		return (
			<>
				{sand_data ? (
					<div className="sandbox-container">
						{tabValue === 2 && (
							<div className="background-part">
								<div className="background-part-left">
									<img src={TopLeftImage} alt="" />
								</div>
								<div className="background-part-right">
									{/* <img src={CirclegroupImage} /> */}
								</div>
							</div>
						)}

						<AppBar className="SandPBar sandbox-profile" color="default" position="static">
							<Toolbar eventkey={0} isopen={user} className="navbarItem">
								<div className="nav_logo_container">
									<IconButton
										className="brand"
										color="inherit"
										aria-label="Logo"
										disableRipple
									>
										<a style={{ display: 'flex', alignItems: 'center' }} href="/">
											<img src={topLogo} alt="nugget.ai icon" width="45" />
										</a>
									</IconButton>
								</div>
								<div className="nav__icons">
									{/* {!sandboxToken && ( */}
									<Button
										className="create-profile-btn"
										variant="outlined"
										// color="primary"
										onClick={this.moveSignupPage}
										style={{
											boxShadow: 'none',
											color: 'white',
											borderColor: 'white'
										}}
									>
										Job searching? Create your profile
									</Button>
									{/* )} */}
									{/* {analyticsToken && (
										<Button
											className="create-profile-btn"
											variant="contained"
											color="primary"
											onClick={this.handleShowPP}
											style={{ boxShadow: 'none', marginLeft: 20 }}
										>
											Hiring? Add to Talent Group
										</Button>
									)} */}
								</div>
							</Toolbar>
						</AppBar>

						<div>
							<SandboxPublic
								data={sand_data}
								currentSandId={uuid}
								chapterResult={sChapterResult}
								displayStory={this.displayStoryTab}
							/>
						</div>
						{/* {analyticsUser && analyticsToken && (
							<AddToPipeline
								open={showAddToPP}
								token={analyticsToken}
								onClose={this.handleShowPP}
								user={analyticsUser}
							/>
						)} */}
					</div>
				) : (
					errorMsg && (
						<div className={`error-msg-container status-${errorMsg.status}`}>
							{errorMsg.status === 404 ? (
								<i className="bx bx-x-circle" />
							) : (
								<i className="bx bx-error" />
							)}
							<h1>{errorMsg.msg}</h1>
						</div>
					)
				)}
			</>
		)
	}
}

SandboxProfile.contextTypes = {
	t: PropTypes.func
}

SandboxProfile.propTypes = {
	getSandDataRequestByProfile: PropTypes.func.isRequired,
	getCategory: PropTypes.func,
	getEChapterResult: PropTypes.func,
	sandProfileData: PropTypes.object,
	errorMsg: PropTypes.shape({
		status: PropTypes.number,
		msg: PropTypes.string
	}),
	categories: PropTypes.array
}

const mapStateToProps = state => ({
	loginTStatus: state.sandboxReducer.loginTStatus,
	sandProfileData: state.sandboxReducer.sand_data,
	categories: state.categoryReducer.categories,
	errorMsg: state.sandboxReducer.getUserError,
	sChapterResult: state.sandboxReducer.sChapterResult,
	updateStatus: state.chapterReducer.updateStatus
})

const mapDispatchProps = {
	getSandDataRequestByProfile,
	getCategory,
	getEChapterResult
}

export default withRouter(connect(mapStateToProps, mapDispatchProps)(SandboxProfile))

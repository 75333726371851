import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles, Divider, Grid, Button, Dialog } from '@material-ui/core'
import clsx from 'clsx'

const padding = 30

const styles = {
	root: {
		width: '100%',
		maxWidth: 621,
		borderRadius: 8,
		boxShadow: '0 15px 30px rgba(141, 151, 200, 0.15)',
		backgroundColor: 'white',
		padding,
		margin: 'unset !important',
		paddingTop: padding - 10,
		paddingBottom: padding + 15,
		// position: 'relative',
		boxSizing: 'border-box'
	},
	title: {
		fontSize: 22,
		fontWeight: 600,
		color: '#4a4a4a'
	},
	header: {
		paddingBottom: padding - 10
	},
	footer: {
		paddingTop: padding + 15
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '50px 20px',
		height: 'unset !important',
		// width: 'fit-content',
		// width: '100vw',
		margin: '0 auto',
		minHeight: '100%',
		boxSizing: 'border-box',
		zIndex: 2000
	},
	button: {
		fontSize: '1em',
		border: '2px solid #7D6EF1',
		color: 'white',
		height: 48,
		fontWeight: 500,
		paddingTop: 8,
		paddingBottom: 8,
		borderRadius: 6
	},
	cancelButton: {
		color: '#7D6EF1'
	},
	okButton: {
		backgroundColor: '#7D6EF1',
		border: 'none',
		paddingTop: 9,
		paddingBottom: 9,
		'&:hover': {
			backgroundColor: '#7D6EF1'
		}
	},
	content: {
		paddingTop: padding
	},
	modal: {
		overflowY: 'auto'
	},
	divider: {
		width: `calc(100% + ${padding * 2}px)`,
		backgroundColor: '#dee7f7',
		marginLeft: -padding,
		position: 'unset !important'
	}
}

const BaseModal = ({
	open,
	className,
	onClose,
	classes,
	children,
	title,
	footer = true,
	okText = 'Ok',
	cancelText = 'Cancel',
	onOk,
	onCancel,
	closeOnCancel = true,
	closeOnOk = true,
	customOnOk = false,
	customOnCancel = false,
	okLoading = false,
	buttonProps,
	okButtonProps,
	cancelButtonProps
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll="body"
			// classes={{ paper: clsx(classes.root, className) }}
			// classes={{ container: classes.container }}
			classes={{
				paper: clsx(classes.root, className),
				container: classes.container,
				root: classes.modal
			}}
			disableEnforceFocus
			// className={classes.modal}
		>
			<div className={classes.header}>
				<Typography variant="h5" component="h5" className={classes.title}>
					{title}
				</Typography>
			</div>
			<Divider className={classes.divider} />
			<div className={classes.content}>{children}</div>
			{footer && (
				<Grid
					container
					className={classes.footer}
					spacing={24}
					alignItems="center"
					justify="flex-end"
				>
					<Grid item xs={6} md={3}>
						<Button
							fullWidth
							className={clsx(classes.button, classes.cancelButton)}
							variant="outlined"
							onClick={
								onCancel
									? customOnCancel
										? onCancel
										: e => {
												onCancel(e)
												if (closeOnCancel) onClose()
										  }
									: onClose
							}
							{...buttonProps}
							{...cancelButtonProps}
						>
							{cancelText}
						</Button>
					</Grid>
					<Grid item xs={6} md={3}>
						<Button
							className={clsx(classes.button, classes.okButton)}
							fullWidth
							variant="contained"
							onClick={
								onOk
									? customOnOk
										? onOk
										: e => {
												onOk(e)
												if (closeOnOk) onClose()
										  }
									: onClose
							}
							{...buttonProps}
							{...okButtonProps}
						>
							{okLoading ? (
								<i
									className="bx bx-loader-alt bx-spin"
									style={{ fontSize: '1.3em', color: 'inherit' }}
								/>
							) : (
								okText
							)}
						</Button>
					</Grid>
				</Grid>
			)}
		</Dialog>
	)
}

BaseModal.propTypes = {
	buttonProps: PropTypes.object,
	cancelButtonProps: PropTypes.object,
	cancelText: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	classes: PropTypes.shape({
		button: PropTypes.string,
		cancelButton: PropTypes.string,
		container: PropTypes.string,
		content: PropTypes.string,
		divider: PropTypes.string,
		footer: PropTypes.string,
		header: PropTypes.string,
		modal: PropTypes.string,
		okButton: PropTypes.string,
		root: PropTypes.string,
		title: PropTypes.string
	}),
	closeOnCancel: PropTypes.bool,
	closeOnOk: PropTypes.bool,
	customOnCancel: PropTypes.bool,
	customOnOk: PropTypes.bool,
	footer: PropTypes.bool,
	okButtonProps: PropTypes.object,
	okLoading: PropTypes.bool,
	okText: PropTypes.string,
	onCancel: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	onOk: PropTypes.func,
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired
}

export default withStyles(styles)(BaseModal)

import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import decode from 'jwt-decode'
import cookie from 'react-cookies'
import { push } from 'react-router-redux'
import iziToast from 'izitoast'
import { LocalConvenienceStoreOutlined } from '@material-ui/icons'
import actions from './actions'
import authActions from '../auth/actions'

import { getHeaders } from '../../utils/authUtil'
import { getEndpoint } from '../../utils/urlHelper'

import {
	saveUser,
	saveToken,
	saveLogTime,
	removeUser,
	getUser,
	getToken
} from '../../utils/localStorageUtil'

export function* register() {
	yield takeEvery(actions.REGISTER_SAND_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/signup'),
			method: 'post',
			headers: getHeaders(),
			data: payload,
			withCredentials: true
		}

		try {
			const res = yield call(axios.request, params)
			const { user, success, token } = res.data

			if (success === true) {
				user.sandbox = true
				saveUser(user)
				saveToken(token)
				saveLogTime()
				yield put(actions.registerTSuccess(user))
				yield put(authActions.loginSuccess(user))
			}
		} catch (err) {
			yield put(actions.registerTFailed(err.response.data.msg))
		}
	})
}

export function* oAuthToken() {
	yield takeEvery(actions.OAUTH_SAND_TOKEN, function* ({ payload }) {
		const decodedUser = decode(payload?.split('JWT ')[1])
		const userUUId = decodedUser.uuid
		try {
			saveToken(payload)
			saveLogTime()
			yield put(actions.getSandDataRequest({ userUUId }))
		} catch (err) {
			yield put(actions.loginTFailed(err.response.data.error))
		}
	})
}

export function* login() {
	yield takeEvery(actions.LOGIN_SAND_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/signin'),
			method: 'post',
			headers: getHeaders(),
			data: payload,
			withCredentials: true
		}
		try {
			const res = yield call(axios.request, params)
			const { user, success, token } = res.data
			if (success) {
				user.sandbox = true
				saveUser(user)
				saveToken(token)
				saveLogTime()
				yield put(actions.loginTSuccess(success))
				yield put(authActions.loginSuccess(user))
			} else {
				yield put(actions.loginTFailed(res.data.msg))
			}
		} catch (err) {
			yield put(actions.loginTFailed(err.response.data.error))
		}
	})
}

export function* logout() {
	yield takeEvery(actions.LOGOUT_SAND_REQUEST, function* () {
		// const params = {
		// 	url: getEndpoint('sandbox/signout'),
		// 	method: 'post',
		// 	headers: getHeaders(),
		// }

		try {
			// yield call(axios.request, params)
			yield put(actions.logoutTSuccess())
			yield put(authActions.logoutSuccess())
			// yield push('/login-talent')
		} catch (err) {
			// yield push('/login-talent')
			yield put(actions.logoutTFailed())
		} finally {
			removeUser()
		}
	})
}

export function* setBadgeNotification() {
	yield takeEvery(actions.SET_BADGE_NOTIFICATION_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/setNotification/${payload}`),
			method: 'post',
			headers: getHeaders()
		}
		try {
			const res = yield call(axios.request, params)
			const { success } = res.data
			yield put(actions.setBadgeNotificationSuccess(payload))
		} catch {}
	})
}

export function* setAllBadgeNotification() {
	yield takeEvery(actions.SET_ALL_BADGE_NOTIFICATION_REQUEST, function* () {
		const params = {
			url: getEndpoint('sandbox/setAllNotifications'),
			method: 'post',
			headers: getHeaders()
		}
		try {
			const res = yield call(axios.request, params)
			yield put(actions.setAllBadgeNotificationSuccess())
		} catch {}
	})
}

export function* getAllChallenge() {
	yield takeEvery(actions.GET_ALL_CHALLENGE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('challenge/sandbox'),
			method: 'get',
			headers: getHeaders()
		}
		try {
			const res = yield call(axios.request, params)
			const { challenges } = res.data
			yield put(actions.getAllChallengeSuccess(challenges))
		} catch (err) {
			yield put(actions.getAllChallengeFailed(err))
		}
	})
}

export function* getEChapterResult() {
	yield takeEvery(actions.GET_ECHAPTER_RESULT_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getChapterResult/${payload}`),
			method: 'get',
			headers: getHeaders(),
			params: {
				isSJT: true
			}
		}

		try {
			const res = yield call(axios.request, params)
			const { chapterResults } = res.data
			yield put(actions.getEChapterResultSuccess(chapterResults))
		} catch (err) {
			const user = getUser()
			if (payload === user?.uuid) {
				yield put(actions.logoutTRequest())
			}
			yield put(actions.getEChapterResultFailed(err))
		}
	})
}

export function* getChallenge() {
	yield takeEvery(actions.GET_CHALLENGE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`challenge/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { challenge } = res.data
			yield put(actions.getChallengeSuccess(challenge))
		} catch (err) {
			yield put(actions.getChallengeFailed(err))
		}
	})
}

export function* setSandProfileInfo() {
	yield takeEvery(actions.SET_PROFILE_INFO_REQUEST, function* ({ payload }) {
		console.log('saga', payload)
		for (var pair of payload?.entries()) {
			console.log(pair[0] + ', ' + pair[1])
			if (pair[0] === 'img') {
				console.log(
					pair[1].text()?.then(res => {
						console.log(res)
					})
				)
			}
		}

		const params = {
			url: getEndpoint('sandbox/setprofiledetails'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			const { sanduser } = res.data
			yield put(actions.setSandProfileInfoSuccess(sanduser))
		} catch (err) {
			yield put(actions.setSandProfileInfoFailed(err))
		}
	})
}

export function* setQuestionnaireValues() {
	yield takeEvery(actions.SET_PD_QUESTIONNAIRE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/setquestionnaire'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			// const res = yield call(axios.request, params);
			yield call(axios.request, params)
			// const { challenge } = res.data;
			yield put(actions.setPDQuestionnaireSuccess())
		} catch (err) {
			yield put(actions.setPDQuestionnaireFailed())
		}
	})
}

export function* getSandboxData() {
	yield takeEvery(actions.GET_SANDDATA_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/getsandboxdata'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)

			const token = cookie.load('sandbox_token')
			if (!token) {
				saveToken(getToken())
			}
			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getSandDataSuccess(sandData))
				yield put(authActions.loginSuccess(sandData))
			} else {
				yield put(actions.getSandDataFailed('no data'))
			}
		} catch (err) {
			yield put(actions.getSandDataFailed(err))
		}
	})
}

export function* getSandboxDataFailed() {
	yield takeEvery(actions.GET_SANDDATA_FAILED, function* () {
		yield put(actions.logoutTRequest())
	})
}

export function* getSandboxByPProfileData() {
	yield takeEvery(actions.GET_SANDDATA_PROFILE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getSandDataRequestByProfileSuccess(sandData))
			} else {
				yield put(actions.getSandDataRequestByProfileFailed('no data'))
			}
		} catch (err) {
			yield put(
				actions.getSandDataRequestByProfileFailed({
					status: err.response.status,
					msg: err.response.data.msg
				})
			)
		}
	})
}

// export function* updateProfileDetails() {
// 	yield takeEvery(actions.UPDATE_PROFILE_INFO_REQUEST, function* ({ payload }) {
// 		const params = {
// 			url: getEndpoint('sandbox'),
// 			method: 'patch',
// 			headers: getHeaders(),
// 			data: payload
// 		}

// 		try {
// 			const res = yield call(axios.request, params)
// 			yield put(actions.updateProfileDetailsSuccess(res.data.result))
// 		} catch (err) {
// 			yield put(actions.updateProfileDetailsFailed(err.response.data.msg))
// 		}
// 	})
// }

export function* updateProfileDetailsOld() {
	yield takeEvery(actions.UPDATE_PROFILE_INFO_REQUEST, function* ({ payload }) {
		console.log('redux saga', payload)
		const { userUUId, data } = payload

		if (!Array.isArray(userUUId)) {
			const params = {
				url: getEndpoint(`sandbox/updateProfileDetails/${userUUId}`),
				method: 'put',
				headers: getHeaders(),
				data: [data]
			}

			try {
				const res = yield call(axios.request, params)
				yield put(actions.updateProfileDetailsSuccess(res))
			} catch (err) {
				yield put(actions.updateProfileDetailsFailed(err))
			}
		} else {
			for (let k = 0; k < userUUId.length; k++) {
				const arr = []

				for (let i = 0; i < data.addNewCell.length; i++) {
					if (data.addNewCell[i][k] !== undefined) {
						arr.push({
							field_name: data.newfield_name[i + 2],
							value: data.addNewCell[i][k],
							type: data.type
						})
					}
				}

				const params = {
					url: getEndpoint(`sandbox/updateProfileDetails/${userUUId[k]}`),
					method: 'put',
					headers: getHeaders(),
					data: arr
				}

				try {
					const res = yield call(axios.request, params)
					yield put(actions.updateProfileDetailsSuccess(res))
				} catch (err) {
					yield put(actions.updateProfileDetailsFailed(err))
				}
			}
		}
	})
}

export function* updateSandboxRecord() {
	yield takeEvery(actions.UPDATE_SANDBOX_RECORD_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/updateSandboxRecord'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			const { result } = res.data
			yield put(actions.updateProfileDetailsSuccess(result))
		} catch (err) {
			yield put(actions.updateSandboxRecordFailed(err))
		}
	})
}

export function* updateSandboxPProfile() {
	yield takeEvery(actions.UPDATE_SANDBOX_PPROFILE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/updateShareUrl'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}
		try {
			const res = yield call(axios.request, params)
			const regResult = res.data.updateResult
			if (regResult) {
				yield put(actions.updateSandPublicProfileSuccess(payload.pp_url))
			} else {
				yield put(actions.updateSandPublicProfileFailed(regResult))
			}
		} catch (err) {
			yield put(actions.updateSandPublicProfileFailed(err))
		}
	})
}

export function* getSandProfileDetails() {
	yield takeEvery(actions.GET_PROFILE_INFO_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/getSandProfileDetails'),
			method: 'get',
			headers: getHeaders(),
			params: {
				isSJT: true
			}
		}

		try {
			const res = yield call(axios.request, params)

			const { sandData } = res.data
			if (Object.keys(sandData).length > 0) {
				yield put(actions.getProfileDetailsSuccess(sandData))
			} else {
				yield put(actions.getProfileDetailsFailed('no data'))
			}
		} catch (err) {
			yield put(actions.getProfileDetailsFailed(err))
		}
	})
}

export function* sendVerifyEmail() {
	yield takeEvery(actions.SEND_VERIFY_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/sendSandVerifyEmail'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			if (res.data.success) {
				yield put(actions.sendVerifyRequestSuccess(res.data.success))
			} else {
				yield put(actions.sendVerifyRequestFailed(res.data.success))
			}
			//
		} catch (err) {
			yield put(actions.sendVerifyRequestFailed(err))
		}
	})
}

export function* validateSandUser() {
	yield takeEvery(actions.VALIDATE_SAND_USER, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/validateSandUser'),
			method: 'post',
			headers: {
				Authorization: payload.token
			}
		}

		try {
			const res = yield call(axios.request, params)
			const userInfo = res.data.user
			const { token } = res.data
			saveUser(userInfo)
			saveToken(token)
			yield put(actions.validateSandUserSuccess(userInfo))
			yield put(authActions.loginSuccess(userInfo))
			// yield put(push('/dashboard'));
		} catch (err) {
			// yield put(actions.loginFailed(err.response.data.error));
		}
	})
}

export function* changePassword() {
	yield takeEvery(actions.CHANGE_PASSWORD_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/change-password'),
			method: 'post',
			data: payload,
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.changePasswordSuccess(res.data.user.email))
		} catch (err) {
			yield put(actions.changePasswordFailed(err.response.data.message))
		}
	})
}

const delay = time => new Promise(resolve => setTimeout(resolve, time))

export function* eraseErrorMsg() {
	yield takeEvery(actions.CHANGE_PASSWORD_FAILED, function* () {
		// yield call(delay, 1000)
		yield put(actions.eraseErrorMsg())
	})
}

export function* getSandboxCoins() {
	yield takeEvery(actions.GET_SANDBOX_COINS_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getCoins/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getCoinsSuccess(res.data.coins))
		} catch (err) {
			yield put(actions.getCoinsFailed(err))
		}
	})
}

export function* getMarketCards() {
	yield takeEvery(actions.GET_MARKET_CARDS_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/marketCards'),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getMarketCardsSuccess(res.data.data))
		} catch (err) {
			yield put({ type: actions.GET_MARKET_CARDS_FAILED })
		}
	})
}

export function* buyMarketCard() {
	yield takeEvery(actions.BUY_MARKET_CARD_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/marketCard/${payload}`),
			method: 'post',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			iziToast.success({
				title: 'Success',
				message: res.data.msg
			})
			yield put(actions.buyMarketCardSuccess({ id: payload, coins: res.data.coins }))
		} catch (err) {
			if (err?.response?.data?.msg) {
				iziToast.error({
					title: 'Error',
					message: err.response.data.msg
				})
			}
			yield put(actions.buyMarketCardFailed(err?.response?.data?.msg))
		}
	})
}

export function* setBadgeValueRequest() {
	yield takeEvery(actions.SAVE_BADGE_VALUE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/saveBadgeValue'),
			method: 'post',
			headers: getHeaders(),
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.saveBadgeValueSuccess(res.data))
		} catch (err) {
			yield put(actions.saveBadgeValueFailed(err))
		}
	})
}

export function* getBadgeValueRequest() {
	yield takeEvery(actions.GET_BADGE_VALUE_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint(`sandbox/getBadgeValue/${payload}`),
			method: 'get',
			headers: getHeaders()
		}

		try {
			const res = yield call(axios.request, params)
			yield put(actions.getBadgeValueSuccess(res.data.data))
		} catch (err) {
			yield put(actions.getBadgeValueFailed(err))
		}
	})
}

export function* sendPasswordReseteEmail() {
	yield takeEvery(actions.PASSWORD_RESET_EMAIL_REQUEST, function* ({ payload }) {
		const params = {
			url: getEndpoint('sandbox/send-password-reset'),
			method: 'post',
			data: payload
		}

		try {
			const res = yield call(axios.request, params)
			// saveUser(res.data.data)
			// saveToken(res.data.token)
			yield put(actions.sendPasswordResetSuccess())
			yield push('/login-talent')
		} catch (err) {
			yield put(actions.sendPasswordResetFailed(err.response.data.error))
		}
	})
}

export default function* sandboxSagas() {
	yield all([
		fork(register),
		fork(login),
		fork(logout),
		fork(getAllChallenge),
		fork(getChallenge),
		fork(setSandProfileInfo),
		fork(getSandboxData),
		fork(getSandboxDataFailed),
		fork(getSandboxByPProfileData),
		// fork(updateProfileDetails),
		fork(updateSandboxRecord),
		fork(getSandProfileDetails),
		fork(setQuestionnaireValues),
		fork(setBadgeNotification),
		fork(setAllBadgeNotification),
		fork(sendVerifyEmail),
		fork(validateSandUser),
		fork(changePassword),
		fork(eraseErrorMsg),
		fork(getEChapterResult),
		fork(updateSandboxPProfile),
		fork(getSandboxCoins),
		fork(getMarketCards),
		fork(buyMarketCard),
		fork(setBadgeValueRequest),
		fork(getBadgeValueRequest),
		fork(sendPasswordReseteEmail),
		fork(oAuthToken)
	])
}
